import React, { useState, useEffect, useCallback } from "react";
import { PulseLoader } from "react-spinners";
import Link from "../../atoms/Link";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import PagarPedido from "./PagarPedido";
import Modal from "../../molecules/Modal";
import CardCocina from "../../molecules/Guia/CardCocina";
import AgregarDireccion from "../../molecules/Guia/AgregarDireccion";
import CambiarDireccion from "../../molecules/Guia/CambiarDireccion";
import useModal from "../../Hooks/useModal";
import { useSession } from "../../../context/SessionContext";
import { GET_ADDRESS_BY_USER } from "../../../apiUser";

const HacerPedidos = ({allProducts, total, envio, groupedByCocina }) =>{
    const { user } = useSession();
    const [address, setAddress] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPedido, setTotalPedido] = useState(total);
    const { isOpen:IsOpenCambio, openModal:OpenCambio, closeModal:closeCambio } = useModal();
    const { isOpen:IsOpenPagar, openModal:OpenPagar, closeModal:closePagar } = useModal();
    const { isOpen:IsOpenAgregarDireccion, openModal:OpenAgregarDireccion, closeModal:closeAgregarDireccion} = useModal();

    const fetchAddress = useCallback (async () => {
        try {
            setLoading(true);
            const AddressData = await GET_ADDRESS_BY_USER(user?.id);
            setAddress(AddressData);
        } catch (error) {
            console.error("Error al obtener las direcciones", error);
        } finally {
            setLoading(false);
        }
    }, [user?.id]);

    useEffect(() => {
        fetchAddress();
    }, [fetchAddress]);

    useEffect(() => {
        const costoProductos = total;
        const totalCalculado = costoProductos + envio;
        setTotalPedido(totalCalculado);
    }, [total, envio]);

    const direccionPrincipal = address[0]?.direcciones?.find(direccion => direccion?.main === true) || null;

    const handleClose = () => {
        closeCambio();
        closeAgregarDireccion();
    }

    return( 
        <main className="w-full flex flex-col sm:flex-row">
            <section className="w-full sm:w-2/3 flex flex-col space-y-8 p-4">
                <div className="w-full bg-gray-600 rounded-md p-4 space-y-4">
                    <div className="flex flex-row justify-between items-center">
                        <LargeText text="Direccion de entrega" textBold />
                        <Link value="Cambiar" text="text-yellow-300" onClick={OpenCambio}  />
                    </div>
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                    <LargeText 
                        text={
                            direccionPrincipal !== null ?
                                `${direccionPrincipal.calle}, 
                                ${direccionPrincipal.numero_exterior}, 
                                ${direccionPrincipal.numero_interior}, 
                                ${direccionPrincipal.colonia}, 
                                ${direccionPrincipal.codigo_postal}, 
                                ${direccionPrincipal.municipio}.
                                (${direccionPrincipal.tag})`
                            : 
                            <ButtonForm 
                                bgColor="bg-yellow-300" textColor="text-black" width="w-1/2 m-auto" textBold
                                text={address[0]?.direcciones?.length > 0 ? "Seleccionar dirección" : "Agregar dirección" } 
                                onClick={address[0]?.direcciones?.length <= 0 ? OpenAgregarDireccion : OpenCambio } 
                            />
                        } 
                        textBold 
                    />
                    <LargeText text="Productos por cocina" textBold />
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                    {Object.entries(groupedByCocina).map(([idCocina, cocinaData], index) => {
                        return (
                            <CardCocina
                                key={index}
                                cocina={cocinaData.cocina} 
                                cantidad={cocinaData.products.length} 
                                allProducts={allProducts} 
                            />
                        );
                    })}
                </div>
            </section>
            <section className="w-full h-fit sm:w-1/3 flex flex-col space-y-8 p-4">
                <div className="w-full bg-gray-600 rounded-md p-4 space-y-2 flex flex-col">
                    <LargeText text="Total" textBold /> 
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                        <div className="flex flex-row justify-between">
                            <LargeText text="Costo de productos" /> 
                            <LargeText text={`$ ${total}`} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text="Costo de envío" /> 
                            <LargeText text={`$ ${envio}`} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text="Total" colorText="text-yellow-300" textBold /> 
                            <LargeText text={`$ ${totalPedido}`} textBold />
                        </div>
                        {loading === false ? 
                            <ButtonForm 
                                text="Pagar" textColor="text-black" textBold onClick={OpenPagar}
                                bgColor={ !direccionPrincipal ? "bg-gray-400" : "bg-yellow-300" }
                                disabled={ !direccionPrincipal ? true : false }
                            />
                        :
                            <PulseLoader size={100} color="#0b8500" />
                        }
                        {!direccionPrincipal &&
                            <LargeText text="*Agregue una direccion" colorText="text-yellow-300" textBold/>
                        }
                </div>
                {IsOpenCambio &&
                    <Modal title="Cambiar de dirección" height="h-auto" Button="Agregar" onClick={OpenAgregarDireccion} onClose={handleClose} >
                        <CambiarDireccion options={address[0]?.direcciones} refetch={fetchAddress} addressData={address} onClose={handleClose} />
                        {IsOpenAgregarDireccion &&
                            <Modal title="Agregar una nueva dirección" height="h-auto" onClose={closeAgregarDireccion}>
                                <AgregarDireccion addressData={address} refetch={fetchAddress} onClose={closeAgregarDireccion}/>
                            </Modal>
                        }
                    </Modal> 
                }
                {IsOpenPagar &&
                    <Modal title="Completar pago" height="h-auto" onClose={closePagar} >
                        <PagarPedido onClose={closePagar} totalPedido={totalPedido} direccion={direccionPrincipal} />
                    </Modal> 
                }
                {IsOpenAgregarDireccion &&
                    <Modal title="Agregar una nueva dirección" height="h-auto" onClose={closeAgregarDireccion}>
                        <AgregarDireccion addressData={address} refetch={fetchAddress} onClose={closeAgregarDireccion}/>
                    </Modal>
                }
            </section>
        </main>
    )
}

export default HacerPedidos;