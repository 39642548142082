import { MdPointOfSale } from "react-icons/md";
import { GiArchiveResearch } from "react-icons/gi";
import { LiaCashRegisterSolid } from "react-icons/lia";

export const linksCaja = [
    {
        name:"Caja",
        path:"/TheLaundry/caja",
        icon:MdPointOfSale,
        childs: [], 
    },
    {
        name:"Cortes de caja",
        path:"/TheLaundry/CortesDeCaja",
        icon:LiaCashRegisterSolid,
        childs: [], 
    },
    {
        name:"Historial de pedidos",
        path:"/TheLaundry/historialPedidos",
        icon:GiArchiveResearch,
        childs: [], 
    },
]