import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import ButtonForm from '../../atoms/ButtonForm';
import { useProducts } from '../../../context/ProductContext';
import { CREATE_ORDER, CREATE_ORDER_HISTORY, CREATE_PAYMENT_INTENT, SEND_NOTIFICATION } from "../../../apiTheLaundry/apiUser";
import { PulseLoader } from 'react-spinners';
import { useSession } from '../../../context/SessionContext';
import { useNavigation } from '../../Hooks/useNavigation';
import { v4 as uuidv4 } from "uuid";

const PaymentModal = ({ onClose, totalPedido, direccion }) => {
  const stripe = useStripe();
  const { user } = useSession();
  const elements = useElements();
  const navigate = useNavigation();
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const { groupedByCocina, total, clearCart } = useProducts();
  const uniqueIdRef = uuidv4(); 

  const direccionString =
  `${direccion.numero_exterior}, ${direccion.numero_interior} ${direccion.calle}, ${direccion.colonia}, ${direccion.municipio}, CP ${direccion.codigo_postal}.`;

  const handleCreateOrder = async () => {
    try {
      setLoading(true);
      for (const [id_cocina, products] of Object.entries(groupedByCocina)) {
        const orderData = {
          status: "Preparando",
          total: products.products
            .reduce((acc, product) => acc + product.totalProduct, 0),
          tipo_orden: "Llevar",
          orden: products.products,
          id_cocina: Number(id_cocina),
          id_usuarioExterno: user.id,
          uniqueId: uniqueIdRef,
        };
        await CREATE_ORDER(orderData);
        SEND_NOTIFICATION({ id_cocina: Number(id_cocina) });
      }
      onClose();
    } catch (error) {
      console.error("Error al crear la orden:", error);
    } finally {
      setLoading(false);
    }
  };

  const createOrderHistory = async () => {
    const transformedData = {};
     // eslint-disable-next-line
      for (const [id_cocina, { products }] of Object.entries(groupedByCocina)) {
        products.forEach(product => {
          const id_cocina = product.id_cocina;
            if (!transformedData[id_cocina]) {
              transformedData[id_cocina] = [];
            }
          transformedData[id_cocina].push({
          ...product,
        });
      });
    }
		try {
      setLoading(true);
			const payload = {
				total_general: total,
        total_tarjeta: total,
				orden: transformedData,
        tipo_orden:"Llevar",
        metodo_pago:"Tarjeta",
        status:false,
        id_usuarioExterno: user?.id,
        status_delivery: "Recibido",
        direccion: direccionString,
			};
			await CREATE_ORDER_HISTORY(payload);
		} catch (error) {
			console.error("Error al crear el pedido en el historial", error);
		} finally {
      setLoading(false);
    }
	};

  useEffect(() => {
    if (totalPedido) {
      const createPaymentIntent = async () => {
        try {
          const response = await CREATE_PAYMENT_INTENT(totalPedido);
          if (!response) {
            throw new Error('No response from server');
          }
          const { clientSecret } = response;
          if (!clientSecret) {
            throw new Error('No client secret received');
          }
          setClientSecret(clientSecret);
        } catch (error) {
          console.error('Detailed error:', error);
          toast.error(`Error creating payment intent: ${error.message}`);
        }
      };
      createPaymentIntent();
    }
  }, [totalPedido]);

  const handlePayment = async () => {
    if (!stripe || !elements || !clientSecret) return;

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (error) {
      console.error('Error al procesar el pago:', error.message);
      toast.error(`Error al procesar el pago ${error.message} `);
    } else if (paymentIntent.status === 'succeeded') {
      toast.success('Pago exitoso');
      await handleCreateOrder();
      await createOrderHistory();
      onClose(); 
      clearCart();
      navigate.navigateToMisPedidos();
    }
  };

  return (
    <div>
      {clientSecret ? (
        <div>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '20px',
                  color: '#fde047',
                  '::placeholder': {
                    color: '#ffffff80',
                  },
                },
                invalid: {
                  color: '#fa755a',
                },
              },
            }}
            className="p-3 border-2 rounded-md shadow-sm"
          />
          <ButtonForm 
            textColor='' width='w-1/4 ml-auto' disabled={!stripe || loading}
            bgColor={loading === true ? "bg-gray-300" :'bg-teal-300'}
            onClick={handlePayment} text={loading === true ? <PulseLoader size={30} color="#0b8500" /> : 'Pagar'} 
          />
        </div>
      ) : (
        <PulseLoader color="#0b8500"/>
      )}
    </div>
  );
};

export default PaymentModal;
