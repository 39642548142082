import { useNavigate } from "react-router-dom";

export const useNavigation = () => {
  const navigateToCurrentSection = (sectionId) => {
    navigate(`${window.location.pathname}#${sectionId}`);
  };

  const navigate = useNavigate();

  const navigateToLanding = () => navigate("/");
  const navigateToHome = () => navigate("/TheLaundry");;
  const navigateToLogin = () => navigate("/TheLaundry/login");
  const navigateToCaja = () => navigate("/TheLaundry/caja");
  const navigateToCocina = () => navigate("/TheLaundry/menuAdmin");
  const navigateToConfig = () => navigate("/TheLaundry/config");
  const navigateToAdmin = () => navigate("/TheLaundry/kitchens");
  const navigateToRegister = () => navigate("/TheLaundry/register");
  const navigateToGuia = () => navigate("/TheLaundry/guia");
  const navigateToMisPedidos = () => navigate("/TheLaundry/MisPedidos");

  return {
    navigateToHome,
    navigateToConfig,
    navigateToRegister,
    navigateToLanding,
    navigateToCurrentSection,
    navigateToLogin,
    navigateToCocina,
    navigateToAdmin,
    navigateToCaja,
    navigateToGuia,
    navigateToMisPedidos,
  };
};
