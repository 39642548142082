import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../context/SessionContext";
import { checkSession } from "../../api";
import { toast } from "react-toastify";
import { GET_USER_ID } from "../../apiUser";

const LayoutUsuario = ({ children }) => {
    const navigate = useNavigate();
    const { setUser } = useSession();
    
    useEffect(() => {
        const verify = async () => {
            try {
                const coockie = await checkSession();
                const dataUser = await GET_USER_ID(coockie.id)
                setUser(dataUser);
        } catch (error) {
            toast.error("Sesión no válida");
            navigate(`/ingresar`);
        }};
        verify();
    }, [navigate, setUser]);

    return(
        <div>
            {children}
        </div>
    )
}

export default LayoutUsuario;