import React, { useEffect, useState } from "react";
import { DELETE_ADMIN_KITCHEN, GET_ADMIN_KITCHEN } from "../../apiAdmin";
import DataTable from "react-data-table-component";
import { getKitchen } from "../../api";
import ButtonForm from "../atoms/ButtonForm";
import { MdOutlineEdit, MdOutlineDeleteOutline } from "react-icons/md";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import CreateKitchenAdmin2 from "../adminOrganisms/CreateKitchenAdmin2";
import EditAdminKitchen from "../adminOrganisms/EditAdminKitchen";
import { toast } from "react-toastify";
import H2Custom from "../atoms/H2Custom";
import NoData from "../atoms/NoData";

const AdminsKitchensUsers = () => {
    const [loading, setLoading] = useState(false);
    const [admin, setAdmin] = useState([]);
    const [admins, setAdmins] = useState();
    const [cocinas, setCocinas] = useState();
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openEdit, isOpen: isOpenEdit, closeModal: closeEdit } = useModal();
    const { openModal: openDelete, isOpen: isOpenDelete, closeModal: closeDelete } = useModal();

    const fetchData = async () => {
        try{
            setLoading(true);
            const kitchensData = await getKitchen();
            setCocinas(kitchensData);
            const data = await GET_ADMIN_KITCHEN();
            setAdmins(data);
        } catch (error) {
            console.error("Error al obtener los admins", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        document.title = "Administradores de cocinas - Contenedor";
    }, []); 

    const cocinaName = (id) => {
        let nombre;
        const cocina = cocinas.find(c => c?.id === id)
        nombre = cocina?.nombre;
        return nombre;
    }

    const handleClose = () => {
        closeEdit();
        closeDelete();
        setAdmin(null);
    }

    const handleOpenEdit = (row) => {
        openEdit();
        setAdmin(row);
    }

    const handleOpenDelete = (row) => {
        openDelete();
        setAdmin(row);
    }

    const handleDeleteAdmin = async () => {
        try {
            await DELETE_ADMIN_KITCHEN(admin?.id_user);
            toast.success("Administrador eliminado correctamente");
            handleClose();
            fetchData();
        } catch (error) {
            toast.error("Error al eliminar el administrador");
        }
    }

    const columns = [
        { name:"ID", selector: row => row.id, width: "45px" },
        { name:"Nombre de usuario", selector: row => row?.user?.username },
        { name:"Nombre", selector: row => row.nombre },
        { name:"Apellido", selector: row => row.apellido },
        { name:"Telefono", selector: row => row.telefono },
        { name:"Cocina", selector: row => cocinaName(row.id_cocina )},
        { name: "Acciones", selector: row => 
            <div className="flex flex-row space-x-2">
                <ButtonForm text={<MdOutlineEdit size={35}/>} onClick={() => handleOpenEdit(row)} bgColor="bg-blue-300" width="w-12"/>
                <ButtonForm text={<MdOutlineDeleteOutline size={35}/>} onClick={()=> handleOpenDelete(row)}  bgColor="bg-red-500" width="w-12"/>
            </div>
        }
    ]

    return(
        <div className="space-y-4">
            <div className="flex flex-row items-center justify-between">
                <H2Custom text="Administradores de cocinas" textBold size="text-4xl" colorText="" />
                <ButtonForm text="Crear administrador" bgColor="bg-violet-300" width="w-64" onClick={openModal} />
            </div>
            <div className="border-2 border-gray-200 shadow-lg rounded-lg">
                <DataTable
                    data={admins}
                    columns={columns}
                    progressPending={loading}
                    noDataComponent={<NoData/>}
                />
                {isOpen && 
                    <ModalAdmin title="Agregar administrador" onClose={closeModal}>
                        <CreateKitchenAdmin2 onClose={closeModal} cocinas={cocinas} refetch={fetchData} />
                    </ModalAdmin>
                }
                {isOpenEdit && admin !== null && 
                    <ModalAdmin title="Editar administrador" onClose={handleClose}>
                        <EditAdminKitchen row={admin} onClose={handleClose} refetch={fetchData} />
                    </ModalAdmin>
                }
                {isOpenDelete && admin !== null &&
                    <ModalConfirmAdmin onConfirm={handleDeleteAdmin} onClose={handleClose} />
                }
            </div>
        </div>
    )
}

export default AdminsKitchensUsers;