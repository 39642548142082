import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import useModal from "../Hooks/useModal";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import PedidoHistorial from "../CajaOrganism/PedidoHistorial";
import { GET_WAITERS } from "../../apiAdmin";
import { GET_HISTORIAL } from "../../apiCaja";
import H2Custom from "../atoms/H2Custom";
import NoData from "../atoms/NoData";

const PedidosAdmin = () => {
	const { openModal, isOpen, closeModal } = useModal();
	const [historial, setHistorial] = useState();
	const [orden, setOrden] = useState(null);
	const [row, Setrow] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dataWaiters, setDataWaiters] = useState();

	const getHistorial = async () => {
		try {
			setLoading(true);
			const historialData = await GET_HISTORIAL();
			const waitersData = await GET_WAITERS();
			setHistorial(historialData);
			setDataWaiters(waitersData);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getHistorial();
	}, []);

	useEffect(() => {
		document.title = "Historial de pedidos - Contenedor";
	}, []);

	const handleOpen = (orden, row) => {
		setOrden(orden);
		Setrow(row);
		openModal();
	};

	const handleClose = () => {
		setOrden(null);
		Setrow(null);
		closeModal();
	};

	const getWaiterName = (id_mesero) => {
		const waiter = dataWaiters?.find((waiter) => waiter.id === id_mesero);
		return waiter ? waiter.nombre : "Desconocido";
	};

	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
		},
		{
			name: "Metodo de pago",
			selector: (row) => row.metodo_pago || "Cuenta abierta",
			sortable: true,
		},
		{
			name: "Total",
			selector: (row) => `$${row.total_general} MX`,
			sortable: true,
		},
		{
			name: "Efectivo",
			selector: (row) => `$${row.total_efectivo || 0} MX`,
			sortable: true,
		},
		{
			name: "Tarjeta",
			selector: (row) => `$${row.total_tarjeta || 0} MX`,
			sortable: true,
		},
		{
			name: "Mesa",
			selector: (row) => row.num_mesa,
			sortable: true,
		},
		{
			name: "Mesero",
			selector: (row) => getWaiterName(row.id_mesero),
			sortable: true,
		},
		{
			name: "Fecha",
			selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
			sortable: true,
		},
		{
			name: "Acciones",
			selector: (row) => (
				<div>
					<ButtonForm
						text="Ver"
						bgColor="bg-green-600"
						width="w-16"
						onClick={() => handleOpen(row.orden, row)}
					/>
				</div>
			),
		},
	];

	return (
		<div>
			<div className="w-full border-2 border-gray-200 rounded-lg py-2 overflow-x-auto">
				<div className="flex flex-row justify-center items-center space-x-2 md:space-x-4 px-2">
					<H2Custom
						text="Historial de pedidos"
						textAling="text-center"
						size="text-xl md:text-2xl"
						colorText=""
						textBold
					/>
					<ButtonForm
						text="Actualizar"
						onClick={getHistorial}
						width="w-24"
						bgColor="border border-black hover:border-0 hover:bg-yellow-300"
						textColor="text-black"
					/>
				</div>
				<DataTable
					data={historial}
					columns={columns}
					pagination
					progressPending={loading}
					paginationPerPage={10}
					paginationRowsPerPageOptions={[10, 20, 50, 100]}
					noDataComponent={<NoData />}
				/>
			</div>
			{isOpen && orden !== undefined && row !== null && (
				<ModalAdmin title="Pedido" onClose={handleClose}>
					<PedidoHistorial ordenes={orden} row={row} />
				</ModalAdmin>
			)}
		</div>
	);
};
export default PedidosAdmin;
