import React, { useEffect, useState } from "react";
import { GET_HISTORIAL_CORTES } from "../../apiTheLaundry/apiCaja";
import DataTable from "react-data-table-component";
import { PulseLoader } from "react-spinners";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import CorteDeCaja from "../CajaOrganism/CorteDeCaja";
import LargeText from "../atoms/LargeText";

const CortesDeCajaTL = () => {
    const [cortes, setCortes] = useState();
    const [loading, setLoading] = useState(false);
    const {openModal, isOpen, closeModal} = useModal();

    const fetchHistorialCorte = async () => {
		try {
            setLoading(true);
			const cortesData = await GET_HISTORIAL_CORTES();
			setCortes(cortesData);
		} catch (error) {
			console.error(error);
		} finally {
            setLoading(false);
        }
	}

    useEffect(() => {
        fetchHistorialCorte();
        document.title = "Cortes de caja - Contenedor";
	}, []);

    if (loading) return (
        <PulseLoader color="#0b8500" size={200}/>
    )

    return(
        <div>
            <div className="w-full flex flex-row justify-between items-center">
                <LargeText text="Historial de cortes de caja" size="text-4xl" colorText="text-black" textBold />
                <ButtonForm text="Hacer corte" bgColor="bg-red-500" width="w-fit px-4 ml-auto" onClick={openModal} />
            </div>
            <div className="rounded-lg border-2 border-black shadow-lg">   
                <DataTable 
                    data={cortes}
                    columns={columnsCortes}
                    />
            </div>
            {isOpen &&
                <ModalAdmin title="Corte de caja" onClose={closeModal}>
                    <CorteDeCaja refetchCortes={fetchHistorialCorte} closeCortes={closeModal} />
                </ModalAdmin>
            }
        </div>
    )
}

export default CortesDeCajaTL;

const columnsCortes = [
    {name: "ID", selector: row => row.id},
    {name: "Cantidad retirada", selector: row => `$ ${row.cantidad_retiro} MX`, sortable: true},
    {name: "Cantidad restante", selector: row => `$ ${row.cantidad_restante} MX`, sortable: true},
    {name: "Recibió", selector: row => row.quien_cerro},
    {name: "Caja en turno", selector: row => row.id_caja},
    {name: "Estatus del corte", selector: row => (row.status_corte === true ? "Completo" : "Incompleto"), sortable: true},
    {name: "Comentarios", selector: row => row.comentarios || null},
]