import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import LargeText from "../../atoms/LargeText";

const PedidoUser = ({ ordenes, row, cocinas }) => {

    const agrupadas = Object?.entries(ordenes).map(([id_cocina, productos]) => {
        const cocina = cocinas?.find(c => c?.id?.toString() === id_cocina); 
        return {
            id_cocina,
            nombre: cocina ? cocina?.nombre : "Cargando...",
            productos,     
        };
    });

    return(
        <div className="space-y-4">
            {agrupadas?.map(({id_cocina, nombre, productos })=>(
                <div key={id_cocina} className="bg-white rounded-lg">
                    <DataTable
                        title={nombre}
                        columns={columns}
                        data={productos}
                    />
                </div>
            ))}
            <div className="grid grid-cols-3">
                <LargeText text={`Fecha: ${moment(row.createdAt).format("DD/MM/YYYY HH:mm")}`} colorText="text-white" textBold/>
                <LargeText text={`Pago en: ${row.metodo_pago}`} colorText="text-white" textBold/> 
                <LargeText text={`Total: $${row.total_general} MX`} colorText="text-white" textBold/> 
            </div>
        </div>
    )
}

export default PedidoUser;

const columns = [
    { name: "Nombre", selector: row => row.nombre, sortable: true },
    { name: "Cantidad", selector: row => row.quantity, sortable: true },
    { name: "Total", selector: row => `$${row.totalProduct} MX`, sortable: true },
]