import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SidebarK from "../AdmKitMolecules/SideBarK";
import { checkSession, getKitchenById } from "../../api";
import { GET_ADMIN_KITCHEN_ID } from "../../ApiAdmK";
import { useSession } from "../../context/SessionContext";

const LayoutCocina = ({ children }) => {
	const navigate = useNavigate();
	const { setUser } = useSession();

	useEffect(() => {
		const verify = async () => {
			try {
				const user = await checkSession();
				if (user?.role !== "CocinaAdmin") {
					toast.error("Acceso denegado");
					navigate(`/login`);
					return;
				}
				const adminSession = await GET_ADMIN_KITCHEN_ID(user?.id);
				if (adminSession?.id_cocina !== undefined) {
					const cocina = await getKitchenById(adminSession?.id_cocina);
					setUser(cocina);
				}
			} catch (error) {
				if (error.response.status === 401) {
					toast.error("Sesión no válida");
					navigate(`/login`);
				}
			}
		};

		verify();
	}, [navigate, setUser]);

	return (
		<div className="flex min-h-screen">
			<SidebarK />
			<div className="flex-grow flex flex-col">
				<main className="h-full p-6 bg-white">{children}</main>
			</div>
		</div>
	);
};

export default LayoutCocina;
