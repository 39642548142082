import React, { useState, useEffect, useCallback } from "react";
import { Formik, FieldArray } from "formik";
import ButtonForm from "../../atoms/ButtonForm";
import InputFFAdmin from "../../AdminAtoms/InputFFAdmin";
import { toast } from "react-toastify";
import {
	CREATE_EXTRA,
	DELETE_EXTRA,
	GET_CATEGORIAS,
	GET_CATEGORIAS_PRODUCTS,
	GET_CONSUMABLES,
	GET_EXTRAS_BY_PRODUCT,
	UPDATE_EXTRA,
	UPDATE_PRODUCT,
	UPLOAD_IMAGE,
} from "../../../apiAdmin";
import { MdDelete } from "react-icons/md";
import LargeText from "../../atoms/LargeText";

const EditBebida = ({ onClose, refetch, product }) => {
	const [loading, setLoading] = useState(false);
	const [consumables, setConsumables] = useState([]);
	const [categoriasProdc, setCategoriasProdc] = useState();
	const [file, setFile] = useState(null);
	const [extras, setExtras] = useState([]);
	const [categoriasOptions, setCategoriasOptions] = useState();

		useEffect(() => {
		const getCategorias = async () => {
			try {
				const categoriasData = await GET_CATEGORIAS();
				const mappedCategorias = categoriasData.map((cat) => ({
					label: cat.nombre,
					value: cat.nombre,
				}));
				setCategoriasOptions(mappedCategorias);
			} catch (err) {
				console.error(err);
			}
		};

		getCategorias();
	}, []);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const data = await GET_CONSUMABLES();
			const filteredConsumables = data.filter(
				(consumable) => consumable.almacen.id_cocina === product?.id_cocina
			);
			setConsumables(filteredConsumables);
			const categoriasProductos = await GET_CATEGORIAS_PRODUCTS();
			const mappedCategoriasP = categoriasProductos.map((cat) => ({
				label: cat.nombre, value: cat.prioridad,
			}));
			setCategoriasProdc(mappedCategoriasP);
		} catch (error) {
			console.error("Error al cargar los consumibles y consumibles", error);
		} finally {
			setLoading(false);
		}
	}, [product?.id_cocina]);

	useEffect(() => {
		fetchData();
	}, [product.id, fetchData]);

	const fetchExtras = useCallback(async () => {
		try {
			setLoading(true);
			const data = await GET_EXTRAS_BY_PRODUCT(product.id);
			setExtras(data);
		} catch (error) {
			console.error("Error fetching extras:", error);
			setExtras([]);
		} finally {
			setLoading(false);
		}
	}, [product.id]);

	useEffect(() => {
		fetchExtras();
	}, [product.id, fetchExtras]);

	const optionsReceta = consumables.map((consumable) => ({
		value: consumable.id,
		label: consumable.almacen.nombre,
	}));

	const initialValues = {
		nombre: product.nombre || "",
		descripcion: product.descripcion || "",
		precio: product.precio || 0,
		status: product.status || "",
		receta: product.receta || [],
		extras: extras.length > 0 ? extras : [],
		categoria: product.categoria || "",
		tipo_botella: product.tipo_botella || "",
	};

	const handleSubmit = async (values, { setSubmitting }) => {
		setLoading(true);
		try {
			let imageUrl = "";
			if (file) {
				const folder = "Productos";
				const uploadResponse = await UPLOAD_IMAGE(file, folder);
				imageUrl = uploadResponse.url;
			}
			const filteredExtras = values.extras.filter(
				(extra) => extra.nombre !== null
			);

			const data = {
				nombre: values.nombre,
				imagen: imageUrl,
				descripcion: values.descripcion,
				precio: values.precio,
				status: values.status,
				receta: values.receta,
				categoria: values.categoria,
				tipo_botella: values.tipo_botella,
			};

			for (const extra of filteredExtras) {
				if (extra.id) {
					await UPDATE_EXTRA(extra.id, extra);
				} else {
					await CREATE_EXTRA({ ...extra, id_producto: product.id });
				}
			}

			await UPDATE_PRODUCT(product.id, data);
			toast.success("Bebida actualizada correctamente");
			refetch();
			onClose();
		} catch (error) {
			toast.error("Error al actualizar la bebida");
		} finally {
			setLoading(false);
		}
		setSubmitting(false);
	};

	const handleDeleteExtra = async (id) => {
		try {
			const status = await DELETE_EXTRA(id);
			if (status === 200) {
				toast.success("Extra eliminado correctamente");
				fetchExtras();
			} else {
				toast.error("Error al eliminar extra");
			}
		} catch (error) {
			toast.error("Error al eliminar el extra");
		}
	};

	const validate = (values) => {
		const errors = {};
		if (!values.nombre) {
			errors.nombre = "Campo requerido";
		}
		if (values.precio < 0) {
			errors.precio = "El precio no puede ser negativo";
		}
		if (!values.tipo_botella) {
			errors.tipo_botella = "Es botella de vino?";
		}		
		if (values.receta.length > 0) {
			const recetaErrors = [];
			values.receta.forEach((receta, index) => {
				if (!receta.id) {
					recetaErrors[index] = { id: "El consumible no puede estar vacío" };
				}
			});
		
		values.extras.forEach((extra, index) => {
			if (!extra.nombre) {
				errors[`extras[${index}]`] =
					"Campo requerido";
			}
		});
			if (recetaErrors.length > 0) {
				errors.receta = recetaErrors;
			}
		}
		return errors;
	};

	const options = [
		{ label: "Disponible", value: "Disponible" },
		{ label: "Minimo", value: "Minimo" },
		{ label: "Oculto", value: "Oculto" },
	];

	const optionsBotella = [
		{ label: "Cierto", value: "Cierto" },
		{ label: "Falso", value: "Falso" },
	];

	const options2 = [
		{ label: "Visible", value: "true" },
		{ label: "Oculto", value: "false" },
	];

	return (
		<div>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={validate}
				enableReinitialize={true}
			>
				{({ handleSubmit, values, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className="w-full flex flex-row space-x-4">
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="nombre"
									label="Nombre(s)"
									type="text"
									placeholder="Nombre(s)"
								/>
								<InputFFAdmin
									name="descripcion"
									label="Descripción"
									type="text"
									placeholder="Descripción del producto"
								/>
								<InputFFAdmin name="categoria" label="Categoria del producto" type="select" options={categoriasProdc} />
								<InputFFAdmin
									name="status"
									label="Estatus de stock"
									type="select"
									options={options}
								/>
							<FieldArray name="extras">
							{({ push, remove }) => (
								<div className="mt-4">
									<LargeText
										text={
											loading ? "CARGANDO EXTRAS ESPERE UN MOMENTO" : "Extras"
										}
										colorText=""
										size="text-4xl"
										textBold
									/>
									{values.extras.map((extra, index) => (
										<div
											key={index}
											className="flex flex-row space-x-4 items-center border-violet-300 border p-2 mb-2 rounded-lg"
										>
											<InputFFAdmin
												name={`extras[${index}].nombre`}
												label="Nombre del extra"
												type="text"
												placeholder="Nombre del extra"
												value={extra.nombre}
												onChange={(e) =>
													setFieldValue(
														`extras[${index}].nombre`,
														e.target.value
													)
												}
											/>
											<InputFFAdmin
												name={`extras[${index}].categoria`}
												label="Categoría"
												type="select"
												options={categoriasOptions}
												value={extra.categoria}
												onChange={(e) =>
													setFieldValue(
														`extras[${index}].categoria`,
														e.target.value
													)
												}
											/>
											<InputFFAdmin
												name={`extras[${index}].visibilidad`}
												label="Visible"
												type="select"
												options={options2}
												value={extra.visibilidad}
												onChange={(e) =>
													setFieldValue(
														`extras[${index}].visibilidad`,
														e.target.value
													)
												}
											/>
											<button
												type="button"
												onClick={() =>
													extra.id ? handleDeleteExtra(extra.id) : remove(index)
												}
												disabled={loading}
												className="bg-gray-400 hover:bg-red-500 text-white p-2 rounded text-3xl"
											>
												<MdDelete />
											</button>
										</div>
									))}

									<button
										type="button"
										onClick={() =>
											push({ nombre: "", categoria: "" })
										}
										className="bg-green-500 text-white p-2 rounded"
									>
										Agregar extra
									</button>
								</div>
							)}
						</FieldArray>
							</div>
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="precio"
									label="Precio público"
									type="number"
									placeholder="Precio en pesos mexicanos"
									value={values.precio}
								/>
								<InputFFAdmin name="tipo_botella" label="Es botella de vino" type="select" options={optionsBotella} />
								<InputFFAdmin
									name="imagen"
									label="Imagen"
									type="file"
									onChange={(event) => setFile(event.currentTarget.files[0])}
								/>
								<FieldArray name="receta">
									{({ insert, remove, push }) => (
										<div className="space-y-4">
											{values.receta.length > 0 &&
												values.receta.map((receta, index) => (
													<div key={index} className="flex space-x-4">
														<InputFFAdmin
															name={`receta.${index}.id`}
															label="Consumible"
															type="select"
															options={optionsReceta}
														/>
														<button
															type="button"
															onClick={() => remove(index)}
															className="text-red-500"
														>
															Eliminar
														</button>
													</div>
												))}
											<button
												type="button"
												onClick={() => push({ id: "" })}
												className="text-blue-500 mt-2"
											>
												Agregar ingrediente
											</button>
										</div>
									)}
								</FieldArray>
							</div>
						</div>
						<div className="w-full flex flex-row justify-between items-center">
							<ButtonForm
								type="submit"
								bgColor={loading ? "bg-gray-300" : "bg-violet-300"}
								width="w-1/3 ml-auto"
								text={loading ? "Cargando..." : "Editar"}
								disabled={loading}
							/>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default EditBebida;