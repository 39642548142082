import React, { useState, useEffect } from "react";
import Circle from "../atoms/Circle";
import { useNavigate } from "react-router-dom";
import { getKitchen } from "../../api";

const CirclesBares = () =>{
    const navigate = useNavigate();
    const [cocinas, setCocinas] = useState([]);

    const fetchKitchen = async () =>{
        try{
            const data = await getKitchen();
            const filteredCocinas = data.filter(cocina => cocina.tipo === "Bar");
            setCocinas(filteredCocinas);
        } catch (error) {
            console.log("Error al obtener los datos", error);
        }
    };

    useEffect (()=>{
        fetchKitchen()
    },[]);

    const handleCircleClick = (id) => {
        navigate(`${id}`);
    };

    return(
        <div className="w-full flex flex-col p-4 border-2 border-gray-2 rounded-lg shadow-lg">
            <div className="w-full flex flex-wrap space-x-4">
            {cocinas.map((cocina)=>(
                    <Circle
                        key={cocina.id}
                        cocina={cocina}
                        Height="aspect-square" 
                        Width="w-[45%] sm:w-[15%]" 
                        onClick={()=>handleCircleClick(cocina.id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default CirclesBares;