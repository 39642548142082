import React, { useState } from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";
import { UPDATE_CAJA_START } from "../../apiCaja";

const EditarSaldoCaja = ({ onClose, refetch, caja }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setLoading(true);
            await UPDATE_CAJA_START(caja[0].id, values); 
            toast.success("Caja actualizada correctamente ✅🍽");
            refetch();
            onClose();
        } catch (error) {
            toast.error("Error al actualizar la caja ❌🍽");
            console.error(error);
        } finally {
            setLoading(false);
        }
        setSubmitting(false);
    };

    const initialValues = { 
        dinero_caja:caja[0]?.dinero_caja, 
    }

    const validate = (values) => {
		const errors = {};
        if (!values.dinero_caja) { errors.dinero_caja = "Campo requerido"; }
        else if (values.dinero_caja < 0) { errors.dinero_caja = "No puedes iniciar en negativo" }
		return errors;
	};

    return( 
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className="w-1/3">
                        <InputFFAdmin name="dinero_caja" label="Dinero para inciar" type="number" placeholder="$" />
                    </div>
                    <ButtonForm
                        text={loading ? "Cargando" : "Guardar cambios"} type="submit"
                        bgColor={loading ? "bg-gray-300" : "bg-violet-300"} width="w-1/3 ml-auto mt-6" 
                    />
                </form>
                )}
            </Formik>
        </div>
    )
}

export default EditarSaldoCaja;