import React, { useState, useEffect } from "react";
import Circle from "../../atoms/Circle";
import LargeText from "../../atoms/LargeText";
import Platillos from "./Platillos";
import { useProducts } from "../../../context/ProductContext";
import { GET_ACTIVE_KITCHEN } from "../../../apiUser";
import { PulseLoader } from "react-spinners";

const Cocinas = () =>{
        const { setDataKitchens } = useProducts();
        const [kitchens, setKitchens] = useState([]);
        const [kitchenId, setKitchenId] = useState("");
        const selectedKitchen = kitchens.find(cocina => cocina.id === kitchenId);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState("");

        useEffect (()=>{
                const fetchKitchens = async () =>{
                        try{
                                const data = await GET_ACTIVE_KITCHEN();
                                setKitchens(data);
                                setDataKitchens(data);
                                setLoading(false); 
                        } catch (error) {
                                setError("Error al obtener las cocinas, por favor intenta nuevamente.");
                                console.error("Error al obtener los datos", error);
                        } finally {
                                setLoading(false);
                        }
                };
                fetchKitchens()
        }, [setDataKitchens]);

        if (loading) {
                return <PulseLoader size={300} color="#0b8500" />;  
        }

        if (error) {
                return <div className="text-white text-3xl">{error}</div>;  
        }

        return(
                <div className="w-full sm:h-screen flex flex-col-reverse sm:flex-row">
                        <div className="w-full sm:w-[70%] h-fit sm:h-screen">
                                <Platillos cocina={selectedKitchen} />
                        </div>
                        
                        <div className="w-full sm:w-[30%] flex flex-col items-center pt-4">
                                <LargeText  text="Seleccionar el" textBold={true} size="text-2xl hidden sm:flex" />
                                <LargeText text="Menú" textBold={true} size="text-4xl hidden sm:flex" colorText="text-yellow-300"/>
                                <div className="w-full h-36 sm:h-full flex flex-row sm:flex-wrap space-x-3 sm:justify-center px-4 sm:px-0 sm:py-6 overflow-scroll scroll-behavior-smooth">
                                {kitchens.length > 0 ? (
                                kitchens.map((cocina) => (
                                        <Circle
                                                key={cocina.id}
                                                cocina={cocina}
                                                Height="min-h-20 max-h-20 sm:max-h-36 sm:min-h-36"
                                                Width="min-w-20 max-w-20 sm:max-w-36 sm:min-w-36"
                                                onClick={() => setKitchenId(cocina.id)}
                                                selected={cocina.id === kitchenId}
                                        />
                                ))
                                ) : (
                                        <h2 className="text-yellow-300 text-2xl">No hay cocinas disponibles</h2>
                                )}
                                </div>
                        </div>
                </div>
        );
}

export default Cocinas;