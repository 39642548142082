import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import ButtonForm from "../atoms/ButtonForm";
import Header from "../Organisms/Header";
import { useProducts } from "../../context/ProductContext";
import HacerPedidosTL from "../Organisms/Guia/HacerPedidosTL";
import NoPedidosTL from "../Organisms/Guia/NoPedidosTL";

const GuiaTL = () => {
    const { cart, total, groupedByCocina } = useProducts();
    const navigate = useNavigate();
    const location = useLocation();
    const envio = 50;

    useEffect(() => {
        document.title = "Guia - The Laundry";
    }, []); 

    useEffect(() => {
        if (location.hash === "top"){
            const scrollToTop = () => {
                const section= document.getElementById("top");
                if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                }
            };
            scrollToTop();
        } else {
        const scrollToSec = () => {
            const section= document.getElementById("#");
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        };
        scrollToSec();
        }
    }, [location.hash]);

    return(
        <div className="pt-12 sm:pt-0">
            <Header/> 
            <div className="h-fit w-screen" id="?">
                <div className="w-full min-h-[73vh] sm:pt-16" id="#" >
                <ButtonForm  text="Volver" divStyles="pl-6" icon={<MdOutlineArrowBackIos className="h-6 w-6" />} onClick={() => navigate('/TheLaundry')}/>
                {cart.length ? (
                    <HacerPedidosTL
                        allProducts={cart} 
                        total={total} 
                        envio={envio}
                        groupedByCocina={groupedByCocina}
                    />  
                ):(
                    <NoPedidosTL/>
                )}
                </div>
            </div> 
        </div>
    );
}

export default GuiaTL;