import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { checkSession } from "../../api";
import SidebarCaja from "../cajaMolecules/SideBarCaja";
import { useSession } from "../../context/SessionContext";
import { GET_CAJA_ID } from "../../apiCaja";

const LayoutCaja = ({ children }) => {
	const { setUser } = useSession();
	const navigate = useNavigate();

	useEffect(() => {
		const verify = async () => {
			try {
				const user = await checkSession();
				if (user.role !== "Caja") {
					toast.error("Acceso denegado");
					navigate(`/login`);
				} 
				const cajaSession = await GET_CAJA_ID(user.id);
				setUser(cajaSession);
			} catch (error) {
				navigate(`/login`);
				toast.error("Sesión no válida");
			}
		};

		verify();
	}, [navigate, setUser]);

	return (
		<div className="flex min-h-screen">
			<SidebarCaja />
			<div className="flex-grow flex flex-col">
				<main className="h-full pl-52 pr-6 py-8 bg-white">{children}</main>
			</div>
		</div>
	);
};

export default LayoutCaja;
