import React, { useEffect } from "react";
import background from "../assets/Imagenes/Home1.jpg"
import right from "../assets/Imagenes/right.jpg"
import left from "../assets/Imagenes/left.jpg"
import chef from "../assets/Imagenes/4.jpg"
import LargeText from "../atoms/LargeText";
import ButtonAbout from "../atoms/ButtonAbout";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import Menu from "../Organisms/Menu";
import Titulo from "../atoms/Titulo";
import Header from "../Organisms/Header";
import ContactoForm from "../Organisms/Home/ContactoForm";
import Galeria from "../molecules/Home/Galeria";
import Logo from "../assets/Imagenes/Laublanco.png"

const HomePageLaundry = () => {
	useEffect(() => {
        document.title = "The Laundry";
    }, []); 

	const telefono = "+52 22 25 98 57 14";
	const nombre = "The Laundry";
	const correo = "thelaundrypuebla@gmail.com";
	const logoUrl = "https://archivos-contenedor.s3.us-east-2.amazonaws.com/Images/Logos/logoTheLaundry.png"
	
	const generarVCard = () => {
	const vCard = `
BEGIN:VCARD
VERSION:3.0
FN:${nombre}
TEL:${telefono}
EMAIL:${correo}
PHOTO;VALUE=URI:${logoUrl}
END:VCARD
	`;
	const blob = new Blob([vCard], { type: 'text/vcard' });
	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = `${nombre}.vcf`;
	link.click();
	};
	
	return (
		<main>
			<Header/>
			<section 
				className="w-full h-screen bg-cover bg-center flex flex-col justify-center items-center px-4"
				style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${background})`}}
			>
				<div className="w-fit h-[10%] sm:h-[20%] flex flex-row mt-[10%] sm:mt-[5%] space-x-4 items-center">
					<img src={Logo} alt="LOGO" className="h-full"/>
				</div>
				<div className="w-full sm:w-1/2 h-1/2 flex flex-col justify-around">
					<Titulo
						texto="¡Descubre el sabor de la auténtica parrilla texana en The Laundry!" 
					/>
					<LargeText 
						text="Aquí, cada corte es cocido con el verdadero estilo texano: bajo y lento, 
							en leña y carbón, logrando un ahumado perfecto y sabores intensos" 
						textBold size="text-lg sm:text-xl" textAling="text-center"
					/>
					<LargeText 
						text="Vive una experiencia única que mezcla tradición, sabor y un ambiente inigualable" 
						textBold size="text-lg sm:text-xl" textAling="text-center"
					/>
				</div>
				<div className="w-1/2 h-1/4 flex flex-col items-center">
					<ButtonAbout text="Reservación" onClick={generarVCard}/>
					<MdKeyboardDoubleArrowDown className="text-teal-300" size={300} />
				</div>
			</section>
			<section className="w-full h-auto sm:h-[66vh] flex flex-col sm:flex-row">
				<div className="w-full sm:w-1/2 h-full flex flex-col justify-between pl-4 pr-8 py-8">
					<Titulo 
						texto="Personalización:" 
						textAling="text-start"
					/>
					<LargeText 
						titleColor="text-teal-300"
						title="Eventos Especiales"
						content=" Ya sea un cumpleaños, aniversario o cualquier celebración,
							nuestro chef privado adaptará el menú a la temática o el estilo de tu evento." 
						textBold size="text-xl" textAling="text-center"
					/>
					<LargeText 
						titleColor="text-teal-300"
						title="Menús a Medida"
						content="Trabaja directamente con nuestro chef para diseñar un menú a la medida de tus gustos y necesidades.
							Desde gastronomía internacional hasta cocina de autor, cada plato estará pensado especialmente para ti y tus invitados." 
						textBold size="text-xl" textAling="text-center"
					/>
					<LargeText 
						titleColor="text-teal-300"
						title="Atención Detallada"
						content="Cada aspecto de la experiencia es personalizado, desde las técnicas culinarias utilizadas hasta la presentación final de los platos.
							Creamos experiencias visuales y de sabor inolvidables." 
						textBold size="text-xl" textAling="text-center"
					/>
				</div>
				<div className="relative w-full sm:w-1/4 h-full">
					<img src={left} alt="Left" className="w-full h-full object-cover" />
					<div className="absolute inset-0 bg-gradient-to-b from-black/100 to-transparent"></div>
				</div>
				<div className="relative w-full sm:w-1/4 h-full">
					<img src={right} alt="Right" className="w-full h-full object-cover" />
					<div className="absolute inset-0 bg-gradient-to-b from-black/100 to-transparent"></div>
				</div>
			</section>
				<Menu/>
			<section className="w-full h-auto sm:h-screen flex flex-col sm:flex-row sm:justify-around items-center ">
				<div 
					className="w-screen sm:w-1/4 h-screen sm:h-[75%] bg-cover bg-center rounded-lg"
					style={{backgroundImage:`url(${chef})`}}
				>
				</div>
				<div 
					className="w-full sm:w-1/3 h-full flex flex-col pt-[10vh] sm:top-0"
				>
					<LargeText 
						text="Especial Moments" 
						textBold size="text-2xl" textAlign="text-center"
					/>
					<LargeText 
						text="Private chef" 
						textBold size="text-4xl" textAlign="text-center" colorText="text-teal-300"
					/>
					<LargeText 
						text="Disfruta de una experiencia gastronómica única en la privacidad de tu hogar o evento con nuestro Chef Privado.
							diseñado para aquellos que buscan una cena exclusiva, nuestro chef te sorprenderá con menús personalizados y platos creados
							con ingredientes frescos y de alta calidad.
							Ya sea para una velada intima, una celebracion especial o una reunion de negocios, nuestro chef se encargara de cada detalle, 
							ofreciendo una experiencia culinaria de primer nivel." 
						textBold size="text-2xl" textAlign="text-center"
					/>
						<ButtonAbout text="Informes" />
				</div>
				<div 
					className="w-screen sm:w-1/4 h-screen sm:h-[75%] bg-cover bg-center rounded-lg"
					style={{backgroundImage:`url(${chef})`}}
				>
				</div>
			</section>
			<Galeria/>
			<section className="w-full h-screen flex flex-col sm:flex-row">
				<div className="w-full sm:w-1/2 h-full flex justify-center items-center px-4">
					<iframe 
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.357272384433!2d-98.27376932462211!3d19.003972182185944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc7002b792c67%3A0xa2d22f664f886edd!2sContenedor%20Puebla!5e0!3m2!1sen!2smx!4v1734729583135!5m2!1sen!2smx" 
						className="w-full aspect-square mt-4 sm:mt-0 rounded-lg"
						allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="MAPA THE LAUNDRY"
					>
					</iframe>
				</div>
				<div className="w-full sm:w-1/2 flex flex-col items-end px-4 pt-4 sm:pt-12">
					<LargeText 
						text="Contacto"  colorText="text-teal-300"
						textBold size="text-4xl" textAlign="text-rigth"
					/>
					<ContactoForm/>
				</div>
			</section>
		</main>
	);
};

export default HomePageLaundry;
