import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../Hooks/useNavigation";
import { useProducts } from "../../context/ProductContext";
import useModal from "../Hooks/useModal";
import { FaFacebookF } from "react-icons/fa6";
import { GiShoppingCart } from "react-icons/gi";
import { CgCloseR, CgMenuRight } from "react-icons/cg";
import { FaInstagram, FaPhoneVolume, FaTiktok } from "react-icons/fa";
import Link from "../atoms/Link";
import Button from "../atoms/Button";
import LargeText from "../atoms/LargeText";
import ModalCarrito from "../molecules/Carrito/ModalCarrito";

function Header() {
	const { cart } = useProducts();
	const navigate = useNavigation();
	const { isOpen, openModal, closeModal } = useModal();
	const [menuOpen, isOpenMenu] = useState(false);
	const productCount = cart.length;
	const location = useLocation();

	return (
		<div className="fixed top-0 left-0 w-screen h-12 bg-black bg-opacity-65 sm:bg-opacity-60 flex flex-row items-center justify-end z-30">
			<section className="w-full hidden sm:flex flex-row justify-between items-center px-6">
				<div className="w-1/3 flex">
					<a href="tel:+522225985714">
						<div className="flex flex-row space-x-2">
							<FaPhoneVolume className="text-white" size={30} />
							<LargeText text="Reserva tu lugar al" colorText="text-teal-300" size="text-xl" textBold/>
							<LargeText text="22 25 98 57 14" textBold/>
						</div>
					</a>
				</div>
				<div className="w-1/3 flex flex-row items-center justify-center space-x-4">
					<a
						href="https://www.facebook.com/profile.php?id=61569077751788&locale=es_LA"
						target="_blank"
						className="cursor-pointer"
						rel="noopener noreferrer"
					>
						<FaFacebookF className="h-8 w-8 hover:w-10 hover:h-10 text-white hover:text-teal-300 rounded-lg cursor-pointer"/>
					</a>
					<a
						href="https://www.instagram.com/thelaundry_pue/"
						target="_blank"
						className="cursor-pointer"
						rel="noopener noreferrer"
					>
						<FaInstagram className="h-8 w-8 hover:w-10 hover:h-10 rounded-lg text-white hover:text-teal-300 cursor-pointer"/>
					</a>
					<a
						href="https://www.tiktok.com/@thelaundrypuebla?is_from_webapp=1&sender_device=pc"
						target="_blank"
						className="cursor-pointer"
						rel="noopener noreferrer"
					>
						<FaTiktok className="h-8 w-8 hover:w-10 hover:h-10 text-white hover:text-teal-300 rounded-lg cursor-pointer"/>
					</a>
				</div>
				<div className="w-auto flex flex-row justify-end">
					<Link
					value="Inicio"
					onClick={navigate.navigateToHome}
					show="hidden sm:flex"
					/>
					<Link
						value="Guia"
						onClick={navigate.navigateToGuia}
						show="hidden sm:flex"
					/>
					<Link
						value="Pedidos"
						onClick={navigate.navigateToMisPedidos}
						show="hidden sm:flex"
					/>
					<Link
						value="Configuración"
						onClick={navigate.navigateToConfig}
						show="hidden sm:flex"
					/>
				</div>
			</section>
			<a className="flex sm:hidden px-2 mr-auto" href="tel:+522225985714">
					<div className="flex flex-row space-x-2 items-center">
						<FaPhoneVolume className="text-white" size={20} />
						<LargeText text="Reserva" colorText="text-teal-300" textBold/>
						<LargeText text="2225985714" textBold/>
					</div>
				</a>
				<CgMenuRight
					className="visible sm:hidden text-white h-10 w-10 "
					onClick={() => isOpenMenu(true)}
				/>
			{productCount > 0 && !isOpen ? (
				<Link
					icon={<GiShoppingCart className="w-10 h-10 flex" />}
					onClick={isOpen === true ? closeModal : openModal}
					status={isOpen}
					productCount={productCount}
				/>
			) : (
				<Link
					icon={<GiShoppingCart className="w-10 h-10 flex" />}
					onClick={isOpen === true ? closeModal : openModal}
					status={isOpen}
					productCount={productCount}
				/>
			)}
			{isOpen && <ModalCarrito onClose={closeModal} />}
			{menuOpen === true && isOpen === false && (
				<div className="fixed top-0 right-0 h-screen w-[90%] bg-black bg-opacity-90 flex flex-col justify-around items-center rounded-l-3xl px-4">
					<div className="w-full flex justify-between items-center">
						<CgCloseR
							className="w-10 h-10 text-teal-300"
							onClick={() => isOpenMenu(false)}
						/>
					</div>
					<div className="w-full flex flex-col justify-center items-center space-y-4 pb-4 border-b-2 border-teal-300 ">
					<Button
							text="Inicio"
							largeMargin={location.pathname === "/TheLaundry" ? true : false}
							onClick={navigate.navigateToHome}
						/>
						<Button
							text="Guia"
							largeMargin={location.pathname === "TheLaundry/guia" ? true : false}
							onClick={navigate.navigateToGuia}
						/>
						<Button
							text="pedidos"
							largeMargin={location.pathname === "TheLaundry/MisPedidos" ? true : false}
							onClick={navigate.navigateToMisPedidos}
						/>
						<Button
							text="Configuración"
							largeMargin={location.pathname === "TheLaundry/config" ? true : false}
							onClick={navigate.navigateToConfig}
						/>
					</div>
					<div className="w-full flex flex-col space-y-4">
						<LargeText
							text="Redes sociales"
							colorText="text-teal-300"
							textAling="text-center"
							textBold
						/>
						<div className=" w-full flex flex-row ">
							<div className="w-1/2 flex flex-row justify-around">
								<a
									href="https://www.facebook.com/profile.php?id=61569077751788&locale=es_LA"
									target="_blank"
									className="cursor-pointer"
									rel="noopener noreferrer"
								>
									<FaFacebookF className="h-8 w-8 bg-blue-700 text-white pt-1.5 rounded-full" />
								</a>
								<a
									href="https://www.instagram.com/thelaundry_pue/"
									target="_blank"
									className="cursor-pointer"
									rel="noopener noreferrer"
								>
									<FaInstagram className="h-8 w-8 p-1 rounded-full bg-gradient-to-tr from-pink-500 via-pink-500 to-yellow-300 text-white" />
								</a>
								<a
									href="https://www.tiktok.com/@thelaundrypuebla?is_from_webapp=1&sender_device=pc"
									target="_blank"
									className="cursor-pointer"
									rel="noopener noreferrer"
								>
									<FaTiktok className="h-8 w-8 text-white bg-black p-1 rounded-full" />
								</a>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Header;
