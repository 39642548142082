import React from "react";
import Imagen1 from "../../assets/Imagenes/galeria1.jpg"
import Imagen2 from "../../assets/Imagenes/galeria2.jpg"
import Imagen3 from "../../assets/Imagenes/galeria3.jpg"
import Imagen4 from "../../assets/Imagenes/galeria4.jpg"

const Galeria = () => {
    return (
        <section className="h-full sm:h-1/3 flex flex-col sm:flex-row">
            <img src={Imagen1} className="w-full sm:w-1/3" alt="imagen"/>
            <div className="w-full sm:w-1/3 flex flex-col">
                <img src={Imagen3} className="w-full " alt="imagen"/>
                <img src={Imagen4} className="w-full " alt="imagen"/>
            </div>
            <img src={Imagen2} className="w-full sm:w-1/3 " alt="imagen"/>
        </section>
    );
}

export default Galeria;