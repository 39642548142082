import React, { useState } from "react";
import { Formik } from "formik";
import ButtonForm from "../../atoms/ButtonForm";
import { toast } from "react-toastify";
import InputFieldFormik from "../../atoms/InputFieldFormik";
import { useSession } from "../../../context/SessionContext";
import { UPDATE_ADDRESS } from "../../../apiUser";
import { PulseLoader } from "react-spinners";

const EditarDireccion = ({ addressData ,onClose, refetch, selected }) =>{
    const { user } = useSession();
    const [loading, setLoading] = useState(false);

    const initialValues ={
        calle: selected.calle || "",
        colonia: selected.colonia || "",
        municipio: selected.municipio || "",
        codigo_postal: selected.codigo_postal ||"",
        tag: selected.tag ||"",
        numero_exterior: selected.numero_exterior ||"",
        numero_interior: selected.numero_interior ||"",
        main: selected.main || false
        
    };

    const validate = (values) => {
        const errors = {};
        if (!values.calle) { errors.calle = "Campo requerido"; }
        else if (values.calle.length > 50 ) { errors.calle = "Máximo 50 caracteres"}
        if (!values.colonia) { errors.colonia = "Campo requerido"; }
        else if (values.colonia.length > 50 ) { errors.colonia = "Máximo 50 caracteres"} 
        if (!values.municipio) { errors.municipio = "Campo requerido"; }
        else if (values.municipio.length > 50 ) { errors.municipio = "Máximo 50 caracteres"}
        if (!values.codigo_postal) { errors.codigo_postal = "Campo requerido"; } 
        else if (!/^\d{5}$/.test(values.codigo_postal)) { errors.codigo_postal = "Debe ser un código postal valido"; }
        if (!values.numero_exterior) { errors.numero_exterior = "Agregar número o S/N"; }
        return errors;
    };

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const existingAddresses = addressData[0]?.direcciones || [];
        
            const updatedAddresses = existingAddresses.map((address) => {
                if (address.id === selected.id) {
                    return { ...address, ...values };
                }
                return address;
            });

            const updatedAddressPayload = {
                address: updatedAddresses,
            };

            await UPDATE_ADDRESS(user.id, updatedAddressPayload);
            toast.success("Dirección actualizada correctamente");
            onClose();
            refetch();
        } catch (error) {
            console.error("Error" ,error);
            toast.error("Error al agregar la direccion");
        } finally {
            setLoading(false);
        }
    }

    return(
        <div className="w-full">
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="w-full">
                    <div className="w-full flex flex-col sm:flex-row sm:space-x-4">
                    <div className="flex flex-col w-full sm:w-1/2 py-2 space-y-2">
                        <InputFieldFormik name="calle" label="Calle" type="text" placeholder="Introduzca la calle" />
                        <InputFieldFormik name="municipio" label="Municipio" type="text" placeholder="Introduzca el municipio" />
                        <InputFieldFormik name="codigo_postal" label="Código postal" type="number" placeholder="Introduzca el código postal" />
                    </div>
                    <div className="flex w-full sm:w-1/2 flex-col py-2 space-y-2">
                        <InputFieldFormik name="colonia" label="Colonia" type="text" placeholder="Introduzca la colonia" />
                        <div className="w-full flex flex-row space-x-2 "> 
                            <InputFieldFormik name="numero_interior" label="Numero interior" placeholder="Num int" type="number" />
                            <InputFieldFormik name="numero_exterior" label="Numero exterior" placeholder="Num ext" />
                        </div>
                        <InputFieldFormik name="tag" label="Etiqueta (opcional)" type="text" placeholder="Introduzca la etiqueta" />
                    </div>
                    </div>
                    <ButtonForm 
                        type="submit" textColor="text-black mt-4" width="w-1/2 ml-auto"
                        text={loading ? <PulseLoader color="#0b8500"/> : "Siguiente"} bgColor={loading ? "bg-gray-300" : "bg-yellow-300"} 
                    />
                </form>
            )}
            </Formik>
        </div>
    );
}

export default EditarDireccion;