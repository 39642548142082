import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL_Laundry,
	withCredentials: true,
	headers: { "Content-Type": "application/json", Platform: "web" },
});

export const GET_KITCHEN_SESSION = async () => {
	try {
		const response = await api.get("/api/kitchenSession");
		return response.data;
	} catch (error) {
		console.error("Error al obtener la cocina", error);
		throw error;
	}
};

export const GET_ADMIN_KITCHEN_SESSION = async () => {
	try {
		const response = await api.get("/api/adminKitchenSession");
		return response.data;
	} catch (error) {
		console.error("Error al obtener el administrador", error);
		throw error;
	}
};

export const GET_ADMIN_KITCHEN_ID = async (id) => {
	try {
		const response = await api.get(`/api/adminKitchen/user/${id}`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener el administrador", error);
		throw error;
	}
};

