import React, { useState, useEffect } from "react";
import { Formik, FieldArray } from "formik";
import ButtonForm from "../atoms/ButtonForm";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { toast } from "react-toastify";
import {
	CREATE_PRODUCT,
	CREATE_EXTRA,
	CREATE_ADICIONAL,
	GET_CATEGORIAS,
	UPLOAD_IMAGE,
	GET_CATEGORIAS_PRODUCTS,
} from "../../apiAdmin";
import { MdDelete } from "react-icons/md";
import LargeText from "../atoms/LargeText";
import { FaQuestion } from "react-icons/fa";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import ProblemProduct from "../AdminAtoms/ProblemsProduct";

const CreateProduct = ({ onClose, refetch, id_cocina }) => {
	const [loading, setLoading] = useState(false);
	const { openModal, closeModal, isOpen } = useModal();
	const [categoriasOptions, setCategoriasOptions] = useState();
	const [categoriasProdc, setCategoriasProdc] = useState();
	const [file, setFile] = useState(null);
	
	const handlePrecioListaChange = (value, setFieldValue) => {
		const listaPrice = parseFloat(value) || 0;
		const purchasePrice = (listaPrice * 0.85).toFixed(2);
		const publicPriceInCents = Math.ceil(purchasePrice * 1.4);
		setFieldValue("precio_lista", value);
		setFieldValue("precio_compra", purchasePrice);
		setFieldValue("precio", publicPriceInCents);
	};

	useEffect(() => {
		const getCategorias = async () => {
			try {
				const categoriasData = await GET_CATEGORIAS();
				const mappedCategorias = categoriasData.map((cat) => ({
					label: cat.nombre,
					value: cat.nombre,
				}));
				setCategoriasOptions(mappedCategorias);
				const categoriasProductos = await GET_CATEGORIAS_PRODUCTS();
				const mappedCategoriasP = categoriasProductos.map((cat) => ({
					label: cat.nombre, value: cat.prioridad,
				}));
				setCategoriasProdc(mappedCategoriasP);
			} catch (err) {
				console.error(err);
			}
		};

		getCategorias();
	}, []);

	const handleSubmit = async (values, { setSubmitting }) => {
		const {
			nombre,
			descripcion,
			precio,
			status,
			extras,
			id_cocina,
			adicionales,
			precio_compra,
			precio_lista,
			tipo,
			categoria
		} = values;
		setLoading(true);
		try {
			let imageUrl = "";
			if (file) {
				const folder = "Productos";
				const uploadResponse = await UPLOAD_IMAGE(file, folder);
				imageUrl = uploadResponse.url;
			}
			const productResponse = await CREATE_PRODUCT({
				nombre,
				imagen: imageUrl,
				descripcion,
				precio,
				status,
				id_cocina,
				precio_compra,
				precio_lista,
				tipo,
				categoria,
			});
			const productId = productResponse?.newProduct?.id;
			if (!productId) {
				throw new Error("El ID del producto no se encontró en la respuesta");
			}
			for (const extra of extras) {
				if (extra.nombre) {
					await CREATE_EXTRA({ ...extra, id_producto: productId });
				}
			}
			for (const adicional of adicionales) {
				if (adicional.nombre) {
					await CREATE_ADICIONAL({ ...adicional, id_producto: productId });
				}
			}
			toast.success("Producto y sus asociados creados correctamente");
			refetch();
			onClose();
		} catch (error) {
			console.error("Error al crear el producto y sus asociados:", error);
			toast.error("Error al crear el producto");
		} finally {
			setLoading(false);
		}
		setSubmitting(false);
	};

	const validate = (values) => {
		const errors = {};
		if (!values.nombre) {
			errors.nombre = "Campo requerido";
		}
		if (values.descripcion.length > 255) {
			errors.descripcion = "La descripción no puede tener más de 255 caracteres";
		}
		if (!values.precio) {
			errors.precio = "Campo requerido";
		} else if (values.precio < 0) {
			errors.precio = "El precio no puede ser negativo";
		}
		if (values.precio_compra < 0) {
			errors.precio_compra = "El precio no puede ser negativo";
		}
		if (!values.status || values.status === "") {
			errors.status = "Debes seleccionar un estatus válido";
		}
		values.extras.forEach((extra, index) => {
			if (!extra.nombre) {
				errors[`extras[${index}].nombre`] = "Campo requerido";
			}
		});
		values.adicionales.forEach((adicional, index) => {
			if (!adicional.nombre) {
				errors[`adicionales[${index}].nombre`] = "Campo requerido";
			}
		});
		return errors;
	};

	const options = [
		{ label: "Disponible", value: "Disponible" },
		{ label: "Minimo", value: "Minimo" },
		{ label: "Oculto", value: "Oculto" },
	];

	const options2 = [
		{ label: "Visible", value: "true" },
		{ label: "Oculto", value: "false" },
	];

	return (
		<div>
			<Formik
				initialValues={{
					nombre: "",
					descripcion: "",
					precio: 0,
					precio_compra: 0,
					status: "Disponible",
					id_cocina: id_cocina,
					extras: [],
					adicionales: [],
					precio_lista: 0,
					tipo: "Platillo",
					categoria: null,
				}}
				onSubmit={handleSubmit}
				validate={validate}
				enableReinitialize
			>
				{({ handleSubmit, values, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className="w-full flex flex-row space-x-4">
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="nombre"
									label="Nombre(s)"
									type="text"
									placeholder="Nombre(s)"
								/>
								<InputFFAdmin
									name="descripcion"
									label="Descripción"
									type="text"
									placeholder="Descripción del producto"
								/>
								<InputFFAdmin
									name="status"
									label="Estatus de stock"
									type="select"
									options={options}
								/>
								<InputFFAdmin
									name="imagen"
									label="Imagen"
									type="file"
									accept="image/*"
									onChange={(event) => setFile(event.currentTarget.files[0])}
								/>
							</div>
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="precio_lista"
									label="Precio de lista"
									type="number"
									placeholder="Precio en pesos mexicanos"
									value={values.precio_lista}
									onChange={(e) =>
										handlePrecioListaChange(e.target.value, setFieldValue)
									}
								/>
								<InputFFAdmin
									name="precio"
									label="Precio"
									type="number"
									placeholder="Precio en pesos mexicanos"
									value={values.precio}
									disabled
								/>
								<InputFFAdmin
									name="precio_compra"
									label="Precio de compra"
									type="number"
									placeholder="Precio en pesos mexicanos"
									value={values.precio_compra}
									disabled
								/>
								<InputFFAdmin name="categoria" label="Categoria del producto" type="select" options={categoriasProdc} />
							</div>
						</div>
						<FieldArray name="extras">
							{({ push, remove }) => (
								<div className="mt-4">
									<LargeText
										text="Extras"
										size="text-4xl"
										colorText=""
										textBold
									/>
									{values.extras.map((extra, index) => (
										<div
											key={index}
											className="flex flex-row space-x-4 items-center border-violet-300 border p-2 mb-2 rounded-lg"
										>
											<InputFFAdmin
												name={`extras[${index}].nombre`}
												label="Nombre del extra"
												type="text"
												placeholder="Nombre del extra"
												value={extra.nombre}
												onChange={(e) =>
													setFieldValue(
														`extras[${index}].nombre`,
														e.target.value
													)
												}
											/>
											<InputFFAdmin
												name={`extras[${index}].categoria`}
												label="Categoría"
												type="select"
												options={categoriasOptions}
												value={extra.categoria}
												onChange={(e) =>
													setFieldValue(
														`extras[${index}].categoria`,
														e.target.value
													)
												}
											/>
											<InputFFAdmin
												name={`extras[${index}].visibilidad`}
												label="Visible"
												type="select"
												options={options2}
												value={extra.visibilidad}
												onChange={(e) =>
													setFieldValue(
														`extras[${index}].visibilidad`,
														e.target.value
													)
												}
											/>
											<button
												type="button"
												onClick={() => remove(index)}
												className="bg-gray-400 hover:bg-red-500 text-white p-2 rounded text-3xl"
											>
												<MdDelete />
											</button>
										</div>
									))}
									<button
										type="button"
										onClick={() => push({ nombre: "", categoria: "" })}
										className="bg-green-500 text-white p-2 rounded"
									>
										Agregar extra
									</button>
								</div>
							)}
						</FieldArray>
						<FieldArray name="adicionales">
							{({ push, remove }) => (
								<div className="mt-4">
									<LargeText
										text="Adicionales"
										size="text-4xl"
										colorText=""
										textBold
									/>
									{values.adicionales.map((adicional, index) => (
										<div
											key={index}
											className="flex flex-row space-x-4 items-center border-violet-300 border p-2 mb-2 rounded-lg"
										>
											<InputFFAdmin
												name={`adicionales[${index}].nombre`}
												label="Nombre del adicional"
												type="text"
												placeholder="Nombre del adicional"
												value={adicional.nombre}
												onChange={(e) =>
													setFieldValue(
														`adicionales[${index}].nombre`,
														e.target.value
													)
												}
											/>
											<InputFFAdmin
												name={`adicionales[${index}].categoria`}
												label="Categoría"
												type="select"
												options={categoriasOptions}
												value={adicional.categoria}
												onChange={(e) =>
													setFieldValue(
														`adicionales[${index}].categoria`,
														e.target.value
													)
												}
											/>
											<button
												type="button"
												onClick={() => remove(index)}
												className="bg-gray-400 hover:bg-red-500 text-white p-2 rounded text-3xl"
											>
												<MdDelete />
											</button>
										</div>
									))}
									<button
										type="button"
										onClick={() => push({ nombre: "", categoria: "" })}
										className="bg-green-500 text-white p-2 rounded"
									>
										Agregar adicional
									</button>
								</div>
							)}
						</FieldArray>
						<div className="w-full flex flex-row justify-between items-center">
							<FaQuestion
								size={30}
								className="text-gray-400 hover:text-white hover:bg-violet-300 rounded-full p-1 cursor-pointer"
								onClick={openModal}
							/>
							<ButtonForm
								type="submit"
								bgColor={loading ? "bg-gray-300" : "bg-violet-300"}
								width="w-1/3 ml-auto mt-6"
								text={loading ? "Cargando..." : "Agregar"}
								disabled={loading}
							/>
						</div>
					</form>
				)}
			</Formik>
			{isOpen && (
				<ModalAdmin
					title="Problemas al crear o editar producto"
					onClose={closeModal}
				>
					<ProblemProduct />
				</ModalAdmin>
			)}
		</div>
	);
};

export default CreateProduct;
