import React, { useState } from "react";
import InputFieldFormik from "../../atoms/InputFieldFormik";
import { Formik } from "formik";
import ButtonForm from "../../atoms/ButtonForm";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";

const ContactoForm = () => {
    const [loading, setLoading] = useState(false);

    const formValues = {
        name: "",
        email: "",
        cellphone: "",
        mensaje: "", 
        asunto:"",
    };

    const handleSubmit = async (values, {resetForm}) => {
        try {
            setLoading(true);
            toast.success("Formulario enviado exitosamente");
            resetForm();
        } catch (error) {
            toast.error("Error al enviar los datos");
            console.error("Error al actualizar los datos del usuario:", error);
        } finally {
            setLoading(false);
        }
    };    

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Campo requerido";
        }
        if (!values.asunto) {
            errors.asunto = "Campo requerido";
        }
        if (!values.cellphone) {
            errors.cellphone = "Campo requerido";
        } else if (!/^\d{10}$/.test(values.cellphone)) {
            errors.cellphone = "Debe ser un número de 10 dígitos";
        }
        if (!values.email) {
            errors.email = "Campo requerido";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Debe ser un email válido";
        }
    
        return errors;
    };

    const options = [
        {value: "Queja", label:"Queja" },
        {value: "Reservacion", label:"Reservacion" },
        {value: "Duda", label:"Duda" },
    ];

    return (
        <Formik enableReinitialize initialValues={formValues} validate={validate} onSubmit={handleSubmit} >
        {({ handleSubmit }) => (
            <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col"
            >
                <div className="sm:px-2 space-y-2">
                <InputFieldFormik name="name" label="Nombre(s)" type="text" placeholder="Nombre(s)" />
                <InputFieldFormik name="email" label="Correo electrónico" type="text" placeholder="correo@axample.com" />
                <InputFieldFormik name="cellphone" label="Celular" type="number" placeholder="celular" />
                <InputFieldFormik name="asunto" label="Asunto" type="select" options={options} />
                <InputFieldFormik name="mensaje" label="Mensaje" type="textarea" placeholder="Mensaje" maxLength={255} />
                <div className="pt-4">
                    <ButtonForm 
                        bgColor={loading ? "bg-gray-300" : "bg-teal-300"}
                        textColor="text-black" type="submit" disabled={loading}
                        text={loading ? <PulseLoader color="#0b8500"/> : "Enviar formulario"} 
                    />
                </div>
                </div>
            </form>
        )}
        </Formik>
    );
}

export default ContactoForm;