import React from "react";
import LargeText from "../../atoms/LargeText";
import CocinaModalCarrito from ".././Carrito/CocinaModalCarrito";
import ButtonForm from "../../atoms/ButtonForm";
import { FaRegTrashCan } from "react-icons/fa6";
import { CiCirclePlus } from "react-icons/ci";
import { IoMdCloseCircle } from "react-icons/io";
import useModal from "../../Hooks/useModal";
import { toast } from "react-toastify";
import { useNavigation } from "../../Hooks/useNavigation";
import ModalConfirm from "../ModalConfirm";
import { useProducts } from "../../../context/ProductContext";

const ModalCarrito = ({ onClose }) => {
    const { groupedByCocina, clearCart, total, cart} = useProducts();
    const { isOpen:isOpenConfirm, openModal:openConfirm, closeModal: closeConfirm } = useModal();
    const navigate = useNavigation();

    const HandleVaciar = () =>{
        clearCart();
        toast.warning("Carritos vaciados correctamente");
        onClose();
    }

    const handleClose = () => {
        onClose();
        navigate.navigateToHome();
    }

    return (
        <div className="top-12 w-full max-h-[92vh] sm:h-auto overflow-scroll flex flex-row absolute z-30">
            <div className="w-0 sm:w-2/3" onClick={onClose}></div>
            <div className="h-full w-full sm:w-1/3 bg-black px-4 py-4 space-y-4">
                {cart.length ? (
                <>
                    <div className="h-[10%] flex flex-col border-b-8 border-teal-300">
                        <div className="flex flex-row justify-between items-center">
                            <LargeText text="Tu carrito" textBold={true} size="text-4xl" />
                            <IoMdCloseCircle size={30} className="text-teal-300 cursor-pointer " onClick={onClose}/>
                        </div>
                    </div>
                    {Object.values(groupedByCocina).map(({cocina, products}) => (
                        <CocinaModalCarrito
                            key={cocina.id}
                            cocina={cocina}
                            products={products}
                        />
                    ))}
                    <ButtonForm text={`Ir a pagar  Total: $${total}`} bgColor="bg-teal-300" textColor="text-black" onClick={navigate.navigateToGuia}/>
                    <ButtonForm icon={<FaRegTrashCan />} text="Vaciar todos los carritos" onClick={openConfirm} bgColor="bg-red-500" textColor="text-white"/>
                    {isOpenConfirm &&
                        <ModalConfirm onClose={closeConfirm} onConfirm={HandleVaciar} />
                    }
                </>
                ) : ( 
                    <div className="h-screen w-full flex flex-col items-center justify-center">
                        <LargeText  text="Aún no tienes productos en tu carrito" textBold />
                        <CiCirclePlus  className="w-64 h-64 text-white cursor-pointer" onClick={handleClose}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalCarrito;