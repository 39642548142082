import React from "react";
import "./styles.css";

const Titulo = ({ Letras }) => {
	return (
		<h1 className="titulo text-4xl sm:text-6xl md:text-8xl h-fit w-full">
			{Letras}
		</h1>
	);
};

export default Titulo;
