import React, { useState, useEffect, useCallback } from "react";
import { GET_HISTORIAL } from "../../apiCaja";
import DataTable from "react-data-table-component";
import moment from "moment";
import useModal from "../Hooks/useModal";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import { GET_WAITERS } from "../../apiAdmin";
import FilterKD from "../adminOrganisms/FilterKD";
import H2Custom from "../atoms/H2Custom";
import Ticket from "../CajaOrganism/Ticket";
import NoData from "../atoms/NoData";

const HistorialPedidos = () => {
    const { openModal, isOpen, closeModal } = useModal();
    const [orden, setOrden] = useState(null);
    const [dataOrders, setDataOrders] = useState([]);
    const [originalOrders, setOriginalOrders] = useState([]);
    const [row, setRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataWaiters, setDataWaiters] = useState();

    const today = moment().format('YYYY-MM-DD');

    const getHistorial = useCallback(async () => {
        try {
            setLoading(true);
            const historialData = await GET_HISTORIAL();
            const waitersData = await GET_WAITERS();
            setOriginalOrders(historialData);
            setDataOrders(historialData.filter(order => {
                const orderDate = moment(order.createdAt);
                const start = moment(today).startOf('day');
                const end = moment(today).endOf('day');
                return orderDate.isBetween(start, end, null, '[]');
            }));
            setDataWaiters(waitersData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [today]);

    useEffect(() => {
        getHistorial();
    }, [getHistorial]);

    useEffect(() => {
        document.title = "Historial de pedidos - Contenedor";
    }, []); 

    const handleOpen = (orden, row) => {
        setOrden(orden);
        setRow(row);
        openModal();
    }

    const handleClose = () => {
        setOrden(null);
        setRow(null);
        closeModal();
    }

    const getWaiterName = (id_mesero) => {
        const waiter = dataWaiters?.find(waiter => waiter.id === id_mesero);
        return waiter ? waiter.nombre : "Desconocido"; 
    };

    const columns = [
        {
            name:"ID",
            selector: row => row.id,
            sortable: true,
            width:"60px",
        },
        {
            name:"Metodo de pago",
            selector: row => row.metodo_pago,
            sortable: true
        },
        {
            name:"Total",
            selector: row => `$${row.total_general} MX`,
            sortable: true
        },
        {
            name:"Efectivo",
            selector: row => `$${row.total_efectivo} MX`,
            sortable: true
        },
        {
            name:"Tarjeta",
            selector: row => `$${row.total_tarjeta} MX`,
            sortable: true
        },
        {
            name:"Mesa",
            selector: row => row.num_mesa,
            sortable: true,
            width:"80px",
        },
        {
            name:"Mesero",
            selector: row => getWaiterName(row.id_mesero),
            sortable: true
        },
        {
            name:"Fecha",
            selector: row => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
            sortable: true
        },
        {
            name:"Acciones",
            selector: row => 
            <div>
                <ButtonForm text="Ticket" bgColor="bg-green-600" width="w-24" onClick={()=> handleOpen(row.orden, row)}/>
            </div>,
        },
    ]

    return(
        <div>
            <div className="w-full border-2 border-purple-300 rounded-lg py-2 overflow-x-auto">
                <div className="flex flex-row justify-center items-center space-x-4">
                    <H2Custom text="Historial de pedidos" textAling="text-center" size="text-2xl" colorText="text-pruple-300" textBold />
                    <ButtonForm text="Actualizar" onClick={getHistorial} bgColor="border border-purple-300 hover:border-0 hover:bg-purple-300" textColor="text-black" width="w-24"/>
                </div>
                <div className="px-4">
                    <FilterKD  originalOrders={originalOrders} setDataOrders={setDataOrders} today={today} />
                </div>
                <DataTable
                    data={dataOrders}
                    columns={columns}
                    fixedHeader
                    fixedHeaderScrollHeight="63vh"
                    pagination
                    progressPending={loading}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    noDataComponent={<NoData/>}
                />
            </div>
            {isOpen && orden !== undefined && row !== null &&
                <ModalAdmin title={`Pedido No. ${row.id} `}onClose={handleClose}>
                    <Ticket id={row.id} />
                </ModalAdmin>
            }
        </div>
    )
}

export default HistorialPedidos;