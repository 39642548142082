import React, { useEffect, useState } from "react";
import { GET_CAJA_START, GET_HISTORIAL_CORTES } from "../../apiTheLaundry/apiCaja";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import OpenCaja from "../adminOrganisms/OpenCaja";
import DataTable from "react-data-table-component";
import LargeText from "../atoms/LargeText";
import { PulseLoader } from "react-spinners";
import EditarSaldoCajaTL from "../adminOrganisms/EditarSaldoCaja";

const CajaAdminsTL = () => {
    const [status, setStatus] = useState();
    const [cortes, setCortes] = useState();
    const [loading, setLoading] = useState(false);
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal:openModalEdit, isOpen:isOpenEdit, closeModal:closeModalEdit } = useModal();

	const fetchStartCaja = async () => {
		try {
            setLoading(true);
			const statusData = await GET_CAJA_START();
			setStatus(statusData);
		} catch (error) {
			console.error(error);
            setStatus([]);
		} finally {
            setLoading(false);
        }
	}

    const fetchHistorialCorte = async () => {
		try {
            setLoading(true);
			const cortesData = await GET_HISTORIAL_CORTES();
			setCortes(cortesData);
		} catch (error) {
			console.error(error);
            setCortes([]);
		} finally {
            setLoading(false);
        }
	}

	useEffect(() => {
		fetchStartCaja();
        fetchHistorialCorte();
        document.title = "Caja - Contenedor";
	}, []);

    if (loading) return (
        <PulseLoader color="#0b8500" size={200}/>
    )

    return (
        <div className="h-full flex flex-col space-y-6">
            {Array.isArray(status) && status.length > 0? 
                <div className="h-fit w-full flex flex-row space-x-4">
                    <div className="w-1/2 h-fit p-4 rounded-lg shadow-lg bg-violet-200">
                        <LargeText text="Estatus de la caja" colorText="text-black" textBold/>
                        <DataTable data={status} columns={columns} />
                    </div> 
                    <div className="w-1/2 h-auto flex justify-center items-center p-4 rounded-lg shadow-lg bg-green-100"> 
                        { status[0].status === false ?
                            <ButtonForm text="Editar saldo en caja" bgColor="bg-violet-600" onClick={openModalEdit} width="w-2/3"/> 
                        :
                            <LargeText text="Puedes editar el saldo en caja solo cuando esté cerrada" colorText="text-black" textBold/>
                        }
                    </div>
                </div>
            :
                <div className="w-full h-full flex justify-center items-center bg-violet-200 rounded-lg shadow-lg">
                    <ButtonForm text="Iniciar caja" bgColor="bg-orange-500" width="w-1/3" onClick={openModal} />
                </div>
            }
            <div className="w-full rounded-lg bg-rose-200 p-4 shadow-lg">
                <LargeText text="Historial de cortes de caja" size="text-2xl" colorText="text-black" textBold/>
                <DataTable
                    data={cortes}
                    columns={columnsCortes}
                />
            </div>
            {isOpen &&
                <ModalAdmin title="Iniciar caja" onClose={closeModal}>
                    <OpenCaja refetch={fetchStartCaja} onClose={closeModal}/>
                </ModalAdmin>
            }
            {isOpenEdit &&
                <ModalAdmin title="Editar Saldo en Caja" onClose={closeModalEdit}>
                    <EditarSaldoCajaTL caja={status} refetch={fetchStartCaja} onClose={closeModalEdit} />
                </ModalAdmin>
            }
        </div>
    )
}

export default CajaAdminsTL;

const columns = [
    {name: "Dinero inicial en caja", selector: row => `$ ${row.dinero_caja} MX`},
    {name: "Estatus", selector: row => (row.status === true ? "Abierta" : "Cerrada")},
]

const columnsCortes = [
    {name: "ID", selector: row => row.id},
    {name: "Cantidad retirada", selector: row => `$ ${row.cantidad_retiro} MX`},
    {name: "Cantidad restante", selector: row => `$ ${row.cantidad_restante} MX`},
    {name: "Recibió", selector: row => row.quien_cerro},
    {name: "Caja en turno", selector: row => row.id_caja},
    {name: "Estatus del corte", selector: row => (row.status_corte === true ? "Completo" : "Incompleto"), sortable: true},
    {name: "Comentarios", selector: row => row.comentarios || null},
]