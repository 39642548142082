import React from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useNavigation } from "../../Hooks/useNavigation";
import LargeText from "../../atoms/LargeText";

const NoPedidosTL = () =>{
    const navigate = useNavigation();

    return(
        <div className="w-full h-full flex flex-col justify-center items-center p-4 sm:p-32 space-y-0">
            <div className="w-full bg-gray-600 rounded-md p-10 text-center">
                <LargeText text="No hay productos en tu carrito" size="text-3xl" textBold />
                <LargeText text="Para rastrear algun pedido ve a la seccion de pedidos" size="text-3xl" textBold />
            </div>
                <LargeText text="Ir a ver las cocinas" size="text-2xl"/>
                <HiArrowNarrowLeft className="text-teal-300 cursor-pointer" onClick={navigate.navigateToHome} size={200}/>
        </div>
    );
}

export default NoPedidosTL;