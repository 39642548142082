import React, { useState, useEffect, useCallback } from "react";
import { GiMeal } from "react-icons/gi";
import { PiChefHat } from "react-icons/pi";
import { TbRosetteNumber1 } from "react-icons/tb";
import Circle from "../atoms/Circle";
import LargeText from "../atoms/LargeText";
import Tooltip from "../AdminAtoms/Tooltip";
import { getKitchen } from "../../api";
import { GET_PRODUCTS } from "../../apiAdmin";
import { GET_HISTORIAL } from "../../apiCaja";
import NoData from "../atoms/NoData";

const VentasGrls = () => {
  const [dataOrders, setDataOrders] = useState([]);
  const [kitchens, setKitchens] = useState([]);
  const [topKitchens, setTopKitchens] = useState([]);
  const [mostPopularKitchen, setMostPopularKitchen] = useState(null);
  const [topProducts, setTopProducts] = useState([]);
  const [mostPopularProduct, setMostPopularProduct] = useState(null);
  const [productsDetails, setProductsDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const kitchensData = await getKitchen();
        const ordersData = await GET_HISTORIAL();
        const productsData = await GET_PRODUCTS();
        setDataOrders(ordersData);
        setKitchens(kitchensData);
        setProductsDetails(productsData);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchInfo();
  }, []);

const calculateTopKitchens = useCallback(() => {
  const kitchenCount = {};

  dataOrders.forEach(order => {
    if (order?.orden && typeof order.orden === 'object' && !Array.isArray(order.orden)) {
      Object.keys(order.orden).forEach(key => {
        const kitchenId = parseInt(key, 10); 
        if (kitchenId in kitchenCount) {
          kitchenCount[kitchenId]++;
        } else {
          kitchenCount[kitchenId] = 1;
        }
      });
    } else {
      console.warn('La propiedad orden no es un objeto en este pedido:', order);
    }
  });

  const sortedKitchens = Object.entries(kitchenCount)
    .sort((a, b) => b[1] - a[1]) 
    .map(([kitchenId]) => parseInt(kitchenId));

  const topKitchensArray = sortedKitchens.map(id => kitchens.find(kitchen => kitchen.id === id)).filter(Boolean);

  setTopKitchens(topKitchensArray);
  if (topKitchensArray.length > 0) {
    setMostPopularKitchen(topKitchensArray[0]);
  }
}, [dataOrders, kitchens]);

useEffect(() => {
  calculateTopKitchens();
}, [dataOrders, kitchens, calculateTopKitchens]);

const calculateTopProducts = useCallback(() => {
  const productCount = {};

  dataOrders.forEach(order => {
    if (order?.orden && typeof order.orden === 'object') {
      Object.keys(order.orden).forEach(kitchenId => {
        const productos = order.orden[kitchenId]; 

        if (Array.isArray(productos)) {
          productos.forEach(product => {
            const productId = product.id;

            if (productId in productCount) {
              productCount[productId]++;
            } else {
              productCount[productId] = 1;
            }
          });
        } else {
          console.warn(`Los productos de la cocina ${kitchenId} no son un arreglo en este pedido:`, order);
        }
      });
    } else {
      console.warn('La propiedad orden no es un objeto en este pedido:', order);
    }
  });

  const sortedProducts = Object.entries(productCount)
    .sort((a, b) => b[1] - a[1])
    .map(([productId]) => parseInt(productId));

  const topProductsArray = sortedProducts
    .map(id => productsDetails.find(product => product.id === id))
    .filter(Boolean);

  setTopProducts(topProductsArray);
  if (topProductsArray.length > 0) {
    setMostPopularProduct(topProductsArray[0]); 
  }
}, [dataOrders, productsDetails]);

useEffect(() => {
  calculateTopProducts();
}, [dataOrders, productsDetails, calculateTopProducts]);

  const topKitchensWithoutMostPopular = topKitchens?.filter(kitchen => kitchen.id !== mostPopularKitchen?.id);
  const topProductsWithoutMostPopular = topProducts?.filter(product => product.id !== mostPopularProduct?.id);

  return (
    <div className="w-full flex flex-col space-y-4">
      <div className="w-full h-96 flex flex-col space-y-4">
        <div className="h-1/2 w-full flex flex-row rounded-lg bg-purple-200 shadow-lg border-2 border-gray-200">
          <div className="w-fit h-full p-2">
            <PiChefHat className="text-purple-400" size={50} />
          </div>
          <div className="w-full h-full flex flex-col py-2">
            <LargeText text="Vista de las 5 cocinas más populares" colorText="text-black" textBold />
            <div className="flex flex-row justify-around">
            {loading ? (
              <LargeText text="Cargando..." colorText="text-blue-500" textBold />
            ) : topKitchensWithoutMostPopular.length <= 0 ? (
              <NoData/>
            ) : (
              topKitchensWithoutMostPopular.slice(0, 4).map(cocina => (
                <Circle key={cocina.id} cocina={cocina} Width="w-24" Height="h-24" border="border" />
              ))
            )}
            </div>
          </div>
          {mostPopularKitchen && (
            <div className="relative w-[33%] h-full flex justify-center items-center">
              <Circle cocina={mostPopularKitchen} Width="w-32" Height="h-32" selected/>
              <TbRosetteNumber1 size={60} className="absolute top-8 left-8 z-10 text-yellow-200" />
            </div>
          )}
        </div>
        <div className="h-1/2 w-full flex flex-row rounded-lg bg-rose-200 shadow-lg border-2 border-gray-200">
          <div className="w-fit h-full p-2">
            <GiMeal className="text-rose-400" size={50} />
          </div>
          <div className="w-full h-full flex flex-col">
            <LargeText text="Vista del platillo más vendido" colorText="text-black" textBold />
            <LargeText text="Platillos en general" colorText="text-black" />
            <div className="w-full flex flex-row justify-around">
              {topProductsWithoutMostPopular.length > 0 ? (
                topProductsWithoutMostPopular.slice(0, 4).map(product => (
                  <Tooltip text={product.nombre} key={product.id}>
                    <div className="flex flex-col items-center">
                      <img
                        src={product.imagen}
                        alt={product.nombre}
                        className="w-24 aspect-square border border-gray-300 rounded-lg"
                      />
                    </div>
                  </Tooltip>
                ))
              ) : (
                <NoData/>
              )}
            </div>
          </div>
          {mostPopularProduct && (
            <div className="flex items-center justify-center pr-4">
              <Tooltip text={mostPopularProduct.nombre}>
                <img
                  src={mostPopularProduct.imagen}
                  alt={mostPopularProduct.nombre}
                  className="w-32 aspect-square border border-gray-300 rounded-lg"
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VentasGrls;
