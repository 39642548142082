import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL_Laundry,
	withCredentials: true,
	headers: { "Content-Type": "application/json", Platform: "web" },
});

export const CREATE_KITCHEN = async (data) => {
	try {
		const response = await api.post("/api/kitchen", data);
		return response.data;
	} catch (error) {
		console.error("Error al crear la cocina:", error);
		throw error;
	}
};

export const UPDATE_KITCHEN = async (id, data) => {
	try {
		const response = await api.put(`/api/kitchen/${id}`, data);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar la cocina:", error);
		throw error;
	}
};

export const DELETE_KITCHEN = async (id) => {
	try {
		const response = await api.delete(`/api/kitchen/${id}`);
		return response.status;
	} catch (error) {
		console.error("Error al eliminar la cocina api admin:", error);
		throw error;
	}
};

export const GET_USER_ID = async (userId) => {
	try {
		const response = await api.get(`/api/getUserById/${userId}`);
		if (!response.data || !response.data.id) {
			throw new Error("Usuario no encontrado");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 404) {
			throw new Error("No existe el usuario con el ID proporcionado");
		}
		throw new Error("Error al obtener la información del usuario");
	}
};

export const GET_WAITERS = async () => {
	try {
		const response = await api.get("/api/waiter");
		return response.data;
	} catch (error) {
		console.error("Error al obtener los meseros:", error);
		throw error;
	}
};

export const CREATE_WAITERS = async (waiterData) => {
	try {
		const response = await api.post("/api/waiter", waiterData);
		return response.data;
	} catch (error) {
		console.error("Error al crear mesero: ", error);
		throw error;
	}
};

export const GET_WAITERS_ID = async (id) => {
	try {
		const response = await api.get(`/api/waiter/${id}`);
		if (!response.data || !response.data.id) {
			throw new Error("Mesero no encontrado");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 404) {
			throw new Error("No existe el mesero con el ID proporcionado");
		}
		throw new Error("Error al obtener la información del mesero");
	}
};

export const UPDATE_WAITERS = async (id, data) => {
	try {
		const response = await api.put(`/api/waiter/${id}`, data);
		return response.data;
	} catch (error) {
		console.error("Error al atualizar el mesero", error);
		throw error;
	}
};

export const GET_ORDERS_WAITERS = async (id) => {
	try {
		const response = await api.get(`/api/waiter/${id}/Order`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener los pedidos", error);
		throw error;
	}
};

export const DELETE_WAITERS_ID = async (id) => {
	try {
		const response = await api.delete(`/api/waiter/${id}`);
		return response.status;
	} catch (error) {
		console.error("Error al eliminar el mesero:", error);
		throw error;
	}
};

export const GET_TABLES = async () => {
	try {
		const response = await api.get("/api/table");
		return response.data;
	} catch (error) {
		console.error("Error al obtener los meseros:", error);
		throw error;
	}
};

export const GET_ORDERS = async () => {
	try {
		const response = await api.get("/api/orders");
		return response.data;
	} catch (error) {
		console.error("Error fetching menu order:", error);
		throw error;
	}
};

export const CREATE_PRODUCT = async (productData) => {
	try {
		const response = await api.post("/api/product", productData);
		return response.data;
	} catch (error) {
		console.error("Error al crear producto: ", error);
		throw error;
	}
};

export const UPDATE_PRODUCT = async (id, data) => {
	try {
		const response = await api.put(`/api/product/${id}`, data);
		return response.data;
	} catch (error) {
		console.error("Error al atualizar el producto", error);
		throw error;
	}
};

export const DELETE_PRODUCT = async (id) => {
	try {
		const response = await api.delete(`/api/product/${id}`);
		return response.status;
	} catch (error) {
		console.error("Error al eliminar el producto:", error);
		throw error;
	}
};

export const GET_EXTRAS_BY_PRODUCT = async (id) => {
	try {
		const response = await api.get(`/api/extras/producto/${id}`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener extra:", error);
		throw error;
	}
};

export const CREATE_EXTRA = async (extra) => {
	try {
		const response = await api.post("/api/extras", extra);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const UPDATE_EXTRA = async (id, extra) => {
	try {
		const response = await api.put(`/api/extras/${id}`, extra);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const DELETE_EXTRA = async (id) => {
	try {
		const response = await api.delete(`/api/extras/${id}`);
		return response.status;
	} catch (error) {
		throw error;
	}
};

export const GET_ADICIONALES_BY_PRODUCT = async (id) => {
	try {
		const response = await api.get(`/api/adicionales/producto/${id}`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener adicional:", error);
		throw error;
	}
};

export const CREATE_ADICIONAL = async (adicional) => {
	try {
		const response = await api.post("/api/adicionales", adicional);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const UPDATE_ADICIONAL = async (id, adicional) => {
	try {
		const response = await api.put(`/api/adicionales/${id}`, adicional);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const DELETE_ADICIONAL = async (id) => {
	try {
		const response = await api.delete(`/api/adicionales/${id}`);
		return response.status;
	} catch (error) {
		throw error;
	}
};

export const GET_CATEGORIAS = async () => {
	try {
		const response = await api.get("/api/extrasCategorias");
		return response.data;
	} catch (error) {
		console.error("Error fetching categorias:", error);
		throw error;
	}
};

export const GET_CATEGORIAS_PRODUCTS = async () => {
	try {
		const response = await api.get("/api/productsCategories");
		return response.data;
	} catch (error) {
		console.error("Error fetching categorias:", error);
		throw error;
	}
};

export const GET_PRODUCTS = async () => {
	try {
		const response = await api.get("/api/product");
		return response.data;
	} catch (error) {
		console.error("Error al obtener los productos:", error);
		throw error;
	}
};

export const GET_CONSUMABLES = async () => {
	try {
		const response = await api.get("/api/bar");
		return response.data;
	} catch (error) {
		console.error("Error al obtener los consumibles:", error);
		throw error;
	}
};

export const CREATE_CONSUMABLE = async (data) => {
	try {
		const response = await api.post("/api/bar", data);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const UPDATE_CONSUMABLE = async (id, data) => {
	try {
		const response = await api.put(`/api/bar/${id}`, data);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar el consumible", error);
		throw error;
	}
};

export const DELETE_CONSUMABLE = async (id) => {
	try {
		const response = await api.delete(`/api/bar/${id}`);
		return response.status;
	} catch (error) {
		throw error;
	}
};

export const GET_CASHIERS = async () => {
	try {
		const response = await api.get("/api/cashiers");
		return response.data;
	} catch (error) {
		console.error("Error al obtener los meseros:", error);
		throw error;
	}
};

export const CREATE_CASHIER = async (cashierData) => {
	try {
		const response = await api.post("/api/cashier", cashierData);
		return response.data;
	} catch (error) {
		console.error("Error al crear cajero: ", error);
		throw error;
	}
};

export const UPDATE_CHASHIER = async (id, data) => {
	try {
		const response = await api.put(`/api/cashier/${id}`, data);
		return response.data;
	} catch (error) {
		console.error("Error al atualizar CAJA", error);
		throw error;
	}
};

export const DELETE_CASHIER = async (id) => {
	try {
		const response = await api.delete(`/api/cashier/${id}`);
		return response.status;
	} catch (error) {
		throw error;
	}
};

export const UPDATE_ORDER_HISTORY = async (id, orderData) => {
	try {
		const response = await api.put(`/api/orderHistory/${id}`, orderData);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar la orden:", error);
		throw error;
	}
};

export const CREATE_BITACORA = async (data) => {
	try {
		const response = await api.post("/api/bitacora", data);
		return response;
	} catch (error) {
		console.error("Error al crear la bitacora", error);
		throw error;
	}
};

export const GET_BITACORA = async () => {
	try {
		const response = await api.get("/api/bitacora");
		return response.data;
	} catch (error) {
		console.error("Error al obtener la bitacora", error);
		throw error;
	}
};

export const CREATE_ADMIN_KITCHEN = async (data) => {
	try {
		const response = await api.post("/api/adminKitchen", data);
		return response.data;
	} catch (error) {
		console.error("Error al crear el administrador de cocina", error);
		throw error;
	}
};

export const UPLOAD_IMAGE = async (file, folder) => {
	try {
		const formData = new FormData();
		formData.append("imagen", file);

		const response = await api.post(`/api/upload/images/${folder}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		return response.data;
	} catch (error) {
		console.error("Error al subir la imagen", error);
		throw error;
	}
};

export const GET_ADMIN_KITCHEN = async () => {
	try {
		const response = await api.get("/api/adminKitchen");
		return response.data;
	} catch (error) {
		console.error("Error al obtener administradores de la cocina", error);
		throw error;
	}
};

export const UPDATE_ADMIN_KITCHEN = async (id, data) => {
	try {
		const response = await api.put(`/api/adminKitchen/${id}`, data);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar el admin:", error);
		throw error;
	}
};

export const DELETE_ADMIN_KITCHEN = async (id) => {
	try {
		const response = await api.delete(`/api/adminKitchen/${id}`);
		return response.status;
	} catch (error) {
		throw error;
	}
};

export const GET_ADMINS = async () => {
	try {
		const response = await api.get("/api/admins");
		return response.data;
	} catch (error) {
		console.error("Error al obtener administradores", error);
		throw error;
	}
};

export const CREATE_ADMIN = async (data) => {
	try {
		const response = await api.post("/api/admin", data);
		return response.data;
	} catch (error) {
		console.error("Error al crear el administrador", error);
		throw error;
	}
};

export const UPDATE_ADMIN = async (id, data) => {
	try {
		const response = await api.put(`/api/admin/${id}`, data);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar el admin:", error);
		throw error;
	}
};

export const DELETE_ADMIN = async (id) => {
	try {
		const response = await api.delete(`/api/admin/${id}`);
		return response.status;
	} catch (error) {
		throw error;
	}
};

export const CREATE_CAJA_START = async (data) => {
	try {
		const response = await api.post("/api/cashRegisterStart", data);
		return response.data;
	} catch (error) {
		console.error("Error al iniciar la caja", error);
		throw error;
	}
};
