import React, { useState, useEffect } from "react";
import useModal from "../Hooks/useModal";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import CreateMesero from "../adminOrganisms/CreateMesero";
import { DELETE_WAITERS_ID, GET_WAITERS } from "../../apiAdmin";
import DataTable from "react-data-table-component";
import { MdOutlineEdit, MdOutlineDeleteOutline } from "react-icons/md";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import { toast } from "react-toastify";
import EditMesero from "../adminOrganisms/EditMesero";
import H2Custom from "../atoms/H2Custom";
import NoData from "../atoms/NoData";

const Meseros = () => {
    const [waiter, setWaiter] = useState([]);
    const [waiters, setWaiters] = useState([]);
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openEdit, isOpen: isOpenEdit, closeModal: closeEdit } = useModal();
    const { openModal: openDelete, isOpen: isOpenDelete, closeModal: closeDelete } = useModal();

    const fetchWaiters = async () => {
        try{
            const data = await GET_WAITERS();
            setWaiters(data);
        } catch (error) {
            console.log("Error al obtener los datos", error);
        }
    };

    useEffect (() => {
        fetchWaiters();
    }, []);

    useEffect(() => {
        document.title = "Meseros - Contenedor";
    }, []); 

    const handleOpen = () => {
        openModal();
    };

    
    const handleOpenEdit = (row) => {
        openEdit();
        setWaiter(row);
    }
    
    const handleClose = () => {
        closeEdit();
        closeDelete();
        setWaiter(null);
    }
    
    const handleOpenDelete = (row) => {
        openDelete();
        setWaiter(row);
    }

    const handleDelete = async () => {
        try {
            await DELETE_WAITERS_ID(waiter?.id_user);
                toast.success("Mesero eliminado correctamente");
                fetchWaiters();
                handleClose();
        } catch (error) {
            toast.error("Error al eliminar el mesero");
        }
    }

    const columns = [ 
        { name: "ID", selector: row => row.id },
        { name: "Nombre de usuario", selector: row => row.user?.username },
        { name: "Nombre", selector: row => row.nombre },
        { name: "Apellido", selector: row => row.apellido },
        { name: "Acciones", selector: row => 
            <div className="flex flex-row space-x-2">
                <ButtonForm text={<MdOutlineEdit size={35}/>} onClick={() => handleOpenEdit(row)} bgColor="bg-blue-300" width="w-12"/>
                <ButtonForm text={<MdOutlineDeleteOutline size={35}/>} onClick={()=> handleOpenDelete(row)}  bgColor="bg-red-500" width="w-12"/>
            </div>
        }
    ];

    return (
        <div className="w-full flex flex-col space-y-4">
            <div className="flex flex-row justify-between items-center">
                <H2Custom text="Meseros" colorText="" size="text-4xl" textBold />
                <ButtonForm text="Añadir nuevo mesero" bgColor="bg-purple-300" width="w-64" onClick={handleOpen} />
            </div>
            <div className="border-2 border-gray-200 rounded-lg shadow-lg">
                <DataTable
                    data={waiters}
                    columns={columns}
                    noDataComponent={<NoData/>}
                />
            </div>
                {isOpen && 
                    <ModalAdmin title="Nuevo mesero" onClose={closeModal}>
                        <CreateMesero refetch={fetchWaiters} onClose={closeModal} />
                    </ModalAdmin>
                }
                {isOpenEdit &&
                    <ModalAdmin title="Editar mesero" onClose={handleClose}>
                        <EditMesero onClose={handleClose} mesero={waiter} refetch={fetchWaiters}/>
                    </ModalAdmin>
                }
                {isOpenDelete &&
                    <ModalConfirmAdmin onClose={handleClose} onConfirm={handleDelete} />
                }
        </div>
    );
}

export default Meseros;
