import React, { useEffect, useState, useCallback } from "react";
import LargeText from "../atoms/LargeText";
import { GET_PRODUCTS_BY_KITCHEN } from "../../api";
import DataTable from "react-data-table-component";

const ListProducts = ({ kitchenId, dataOrders }) => {
  const [productsDetails, setProductsDetails] = useState([]);
  const [productsWithSales, setProductsWithSales] = useState([]); 

  const fetchMenu = useCallback(async () => {
    try {
      const data = await GET_PRODUCTS_BY_KITCHEN(kitchenId);
      setProductsDetails(data);
    } catch (error) {
      setProductsDetails([]);
      console.error(error.message);
    }
  }, [kitchenId]);

  useEffect(() => {
    fetchMenu();
  }, [fetchMenu]);

  useEffect(() => {
    if (dataOrders?.length > 0 && productsDetails.length > 0) {
        const productSales = {};

        dataOrders.forEach((order) => {
            if (order?.orden && order?.orden[kitchenId]) {
                const orderProducts = order?.orden[kitchenId];
                orderProducts?.forEach((product) => {
                    const quantity = product.quantity || 1;
                    productSales[product.id] = (productSales[product.id] || 0) + quantity;
                });
            }
        });

        const productWithSales = productsDetails.map((product) => ({
            ...product,
            sales: productSales[product.id] || 0,
        }));

        setProductsWithSales(productWithSales);
    } else {
        setProductsWithSales(productsDetails);
    }
  }, [dataOrders, productsDetails, kitchenId]);

  const columns = [
    { name: "Imagen", selector: row => <img src={row.imagen} alt={row.imagen} className="h-24 aspect-square rounded-lg rounded-lg my-1" /> },
    { name: "Nombre", selector: row => row.nombre },
    { name: "Ventas", selector: row => row?.sales || 0 , sortable: true },
];

  return (
    <div className="w-full flex flex-col bg-slate-200 rounded-lg p-4 space-y-2">
      <LargeText text="Ventas por platillo" colorText="text-black" size="text-2xl" textBold />
      <DataTable
        data={productsWithSales} 
        columns={columns} 
      />
    </div>
  );
};

export default ListProducts;
