import React, { useState, useEffect } from "react";
import Platillos from "./Platillos";
import { getKitchen } from "../../../apiTheLaundry/api";
import { useProducts } from "../../../context/ProductContext";
import ButtonForm from "../../atoms/ButtonForm";
import { PulseLoader } from "react-spinners";

const Cocinas = () =>{
        const { setDataKitchens } = useProducts();
        const [kitchens, setKitchens] = useState([]);
        const [kitchenId, setKitchenId] = useState("");
        const selectedKitchen = kitchens.find(cocina => cocina.id === kitchenId);
        const [loading, setLoading] = useState(true);  
        const [error, setError] = useState("");

        useEffect (()=>{
                const fetchKitchens = async () =>{
                        try{
                                const data = await getKitchen();
                                const filteredData = data.filter((cocina) => cocina.tipo !== "CocinaPadel")
                                setKitchens(filteredData);
                                setDataKitchens(filteredData);
                                setLoading(false); 
                        } catch (error) {
                                setError("Error al obtener las cocinas, por favor intenta nuevamente.");
                                setLoading(false)
                                console.error("Error al obtener los datos", error);
                        }
                };
                fetchKitchens()
        }, [setDataKitchens]);

        if (loading) {
                return <PulseLoader color="#5eead4" size={100}/> ;  
        }

        if (error) {
                return <div>{error}</div>;  
        }

        return(
                <div className="w-full flex flex-col">
                        <div className="w-full flex flex-col items-center pt-4">
                                <div className="w-full sm:h-full flex flex-row justify-around sm:py-2">
                                {kitchens.length > 0 ? (
                                kitchens.map((cocina) => (
                                        <ButtonForm
                                                key={cocina.id}
                                                onClick={() => setKitchenId(cocina.id)}
                                                text={cocina.nombre}
                                                bgColor={kitchenId === cocina.id ? "bg-teal-300" : "bg-rose-200"}
                                                width="w-1/4"
                                                textColor="text-black font-bold"
                                        />
                                ))
                                ) : (
                                        <h2 className="text-yellow-300 text-2xl">No hay cocinas disponibles</h2>
                                )}
                                </div>
                        </div>
                        <div className="w-full h-fit sm:h-screen">
                                <Platillos cocina={selectedKitchen} />
                        </div>
                </div>
        );
}

export default Cocinas;