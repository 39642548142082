import React, { useCallback, useEffect, useState } from "react";
import { GET_ORDERS_BY_EXTERNAL_USER } from "../../apiUser";
import Header from "../organisms/Header";
import { useSession } from "../../context/SessionContext";
import DataTable from "react-data-table-component";
import { PulseLoader } from "react-spinners";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import Modal from "../molecules/Modal";
import PedidoUser from "../molecules/pedidos/PedidoUser";
import { getKitchen } from "../../api";
import moment from "moment";
import NoData from "../atoms/NoData";

const HistorialPedidosUser = () => {
    const { user } = useSession();
    const [pedidos, setPedidos] = useState([]);
    const [cocinas, setCocinas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [orden, setOrden] = useState(null);
    const [row, Setrow] = useState(null);
    const { openModal, isOpen, closeModal } = useModal();

    const fetchOrders = useCallback (async () => {
        try {
            setLoading(true);
            const orders = await GET_ORDERS_BY_EXTERNAL_USER(user?.id);
            setPedidos(orders);
        } catch (error) {
            console.error("Error al obtener las órdenes:", error);
        } finally {
            setLoading(false);
        }
    }, [user?.id]);

    useEffect(() => {
        const getInfo = async () => {
            try {
                const cocinasData = await getKitchen();
                setCocinas(cocinasData);
            } catch (err) {
                console.error(err);
            }
        };
        getInfo();
    }, []);

    useEffect (() => {
        fetchOrders();
    }, [fetchOrders]);

    const handleOpen = (orden, row) => {
        setOrden(orden);
        Setrow(row);
        openModal();
    }

    const handleClose = () => {
        setOrden(null);
        Setrow(null);
        closeModal();
    }

    const columns = [
        { name: "ID", selector: row => row.id, width:"100px", sortable: true },
        // { name: "Estatus del pedido", selector: row => row.status_delivery },
        { name: "Direccion", selector: row => row.direccion },
        {
            name:"Ver productos",
            selector: row => 
            <div>
                <ButtonForm text="Ver" bgColor="bg-yellow-300" textColor="text-black" width="w-32" onClick={()=> handleOpen(row.orden, row)}/>
            </div>,
        },
        { name: "Fecha", selector: row => moment(row.createdAt).format("DD/MM/YY HH:mm") },
        { name: "Total", selector: row => `$ ${row.total_general} MX`},
    ]

    return (
        <div>
            <Header/>
            <section className="pt-16 px-6">
                <div className="p-4 rounded-t-lg">
                    <DataTable
                        data={pedidos}
                        columns={columns}
                        progressPending={loading}
                        customStyles={customStyles}
                        progressComponent={<PulseLoader size={300} color="#0b8500" />}
                        noDataComponent={<NoData/>}
                        pagination
                        paginationPerPage={10} 
                        paginationRowsPerPageOptions={[10, 50, 100]} 
                    />
                </div>
            </section>
            {isOpen && orden !== undefined && row !== null &&
                <Modal title="Pedido" onClose={handleClose} >
                    <PedidoUser ordenes={orden} row={row} cocinas={cocinas} />
                </Modal>
            }
        </div>
    )
}

export default HistorialPedidosUser;

const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#166534',
            color: 'white', 
            fontWeight: 'bold',
        },
    },
    cells: {
        style: {
            paddingLeft: '10px', 
            paddingRight: '10px',
        },
    },
}