import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import useModal from "../Hooks/useModal";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import { GET_PRODUCTS_BY_KITCHEN, getKitchenById } from "../../api";
import CreateBebida from "../adminOrganisms/bar/CreateBebida";
import CardBebida from "../AdminAtoms/CardBebida";
import H2Custom from "../atoms/H2Custom";

const MenuPageBar = () => {
    const { kitchenId } = useParams();
    const [menu, setMenu] = useState([]);
    const [kitchen, setKitchen] = useState(null);
    const [filterText, setFilterText] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { isOpen, openModal, closeModal } = useModal();

    const fetchMenu = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await GET_PRODUCTS_BY_KITCHEN(kitchenId);
            setMenu(data);
        } catch (error) {
            setMenu([]);
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [kitchenId]);

    useEffect(() => {
        fetchMenu();
    }, [fetchMenu]);

    const fetchKitchen = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await getKitchenById(kitchenId);
            setKitchen(data);
        } catch (error) {
            setKitchen(null);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [kitchenId]);

    useEffect(() => {
        fetchKitchen();
    }, [fetchKitchen]);

    useEffect(() => {
        document.title = "Cocina - Contenedor";
    }, []); 

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const filteredMenu = menu.length > 0 &&  menu?.filter(product => 
        product.nombre.toLowerCase().includes(filterText.toLowerCase()) ||
        product.precio.toString().includes(filterText.toLowerCase())
    );

    const handleOpen = () => {
        openModal();
    }

    if (loading) return <div>CARGANDO...</div>;
    if (error) return (
        <div className="w-full h-full">
            <div className="w-full text-center py-4">
                <LargeText text={error} colorText="text-red-500" textBold size="text-4xl" />
            </div>
        </div>
    );

    return (
        <div className="w-full flex flex-col space-y-4">
            <ButtonForm text="Agregar nueva bebida" bgColor={kitchen ? "bg-orange-600" : "bg-gray-300"} width="ml-auto w-1/2 sm:w-1/4" onClick={handleOpen} disabled={!kitchen} />
            {isOpen &&
                <ModalAdmin title={`Agregar bebida a la cocina ${kitchen?.nombre}`} onClose={closeModal}>
                    <CreateBebida refetch={fetchMenu} onClose={closeModal} id_cocina={kitchenId} />
                </ModalAdmin>
            }
            <div className="w-full flex flex-row justify-between items-center">
                <H2Custom text={`Cocina: ${kitchen?.nombre}`} colorText="" textBold />
                <input
                    type="text"
                    placeholder="Buscar por nombre o precio"
                    value={filterText}
                    onChange={handleFilterChange}
                    className="border-2 bg-transparent text-orange-500 border-gray-200 w-1/2 p-2 pr-8 rounded-xl focus:border-orange-500 focus:outline-none ring-0 shadow-lg focus:shadow-orange-400"
                />
            </div>
            <div className="w-full flex flex-wrap justify-between">
                {filteredMenu.length === 0 ? (
                    <div className="w-full text-center py-4">
                        <LargeText text={`"No hay productos disponibles${filterText !== "" ? ` con ${filterText}` : ""}"`} colorText="text-gray-500" textBold size="text-2xl" />
                    </div>
                ) : (
                    menu.length > 0 && filteredMenu.map((product) => (
                        <CardBebida key={product.id} product={product} refetch={fetchMenu} />
                    ))
                )}
            </div>
        </div>
    );
}

export default MenuPageBar;
