import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import { ProductProvider } from "./context/ProductContext";
import { SessionProvider } from "./context/SessionContext";
import Guia from "./components/pages/Guia";
import Menu from "./components/pages/Menu";
import Login from "./components/pages/Login";
import Bar from "./components/adminPages/Bar";
import Users from "./components/adminPages/Users";
import Cajas from "./components/adminPages/Cajas";
import HomePage from "./components/pages/HomePage";
import HomeUser from "./components/pages/HomeUser";
import Register from "./components/pages/Register";
import Admins from "./components/adminPages/Admins";
import AboutPage from "./components/pages/AboutPage";
import Meseros from "./components/adminPages/Meseros";
import CajaMain from "./components/cajaPages/CajaMain";
import Layout from "./components/adminOrganisms/Layout";
import MenuPage from "./components/adminPages/MenuPage";
import Kitchens from "./components/adminPages/Kitchens";
import Almacenes from "./components/adminPages/Almacenes";
import BarDetail from "./components/adminPages/BarDetail";
import MenuProducts from "./components/pages/MenuProducts";
import Configuration from "./components/pages/Configuration";
import LayoutCaja from "./components/CajaOrganism/LayoutCaja";
import MenuPageBar from "./components/adminPages/MenuPageBar";
import PerfilAdmnK from "./components/admKitPages/PerfilAdmK";
import PedidosAdmK from "./components/admKitPages/PedidosAdmK";
import PedidosAdmin from "./components/adminPages/PedidosAdmin";
import MenuViewAdmk from "./components/admKitPages/MenuViewAdmk";
import KitchensUsers from "./components/adminPages/KitchensUsers";
import KitchenDetail from "./components/adminPages/KitchenDetail";
import LayoutCocina from "./components/admKitOrganisms/LayoutCocina";
import HistorialPedidos from "./components/cajaPages/HistorialPedidos";
import AdminsKitchensUsers from "./components/adminPages/AdminsKitchensUsers";
import LoginLaundry from "./componentsTheLaundry/pages/Login";
import UsersLaundry from "./componentsTheLaundry/adminPages/Users";
import CajasLaundry from "./componentsTheLaundry/adminPages/Cajas";
import LayoutLaundry from "./componentsTheLaundry/adminOrganisms/Layout";
import BarLaundry from "./componentsTheLaundry/adminPages/Bar";
import AdminsLaundry from "./componentsTheLaundry/adminPages/Admins";
import MeserosLaundry from "./componentsTheLaundry/adminPages/Meseros";
import KitchensLaundry from "./componentsTheLaundry/adminPages/Kitchens";
import AlmacenesLaundry from "./componentsTheLaundry/adminPages/Almacenes";
import KitchensUsersLaundry from "./componentsTheLaundry/adminPages/KitchensUsers";
import BarDetailLaundry from "./componentsTheLaundry/adminPages/BarDetail";
import MenuPageLaundry from "./componentsTheLaundry/adminPages/MenuPage";
import KitchenDetailLaundry from "./componentsTheLaundry/adminPages/KitchenDetail";
import MenuPageBarLaundry from "./componentsTheLaundry/adminPages/MenuPageBar";
import AdminsKitchensUsersLaundry from "./componentsTheLaundry/adminPages/AdminsKitchensUsers";
import PedidosAdminLaundry from "./componentsTheLaundry/adminPages/PedidosAdmin";
import LayoutCajaLaundry from "./componentsTheLaundry/CajaOrganism/LayoutCaja";
import CajaMainLaundry from "./componentsTheLaundry/cajaPages/CajaMain";
import HistorialPedidosLaundry from "./componentsTheLaundry/cajaPages/HistorialPedidos";
import LayoutCocinaLaundry from "./componentsTheLaundry/admKitOrganisms/LayoutCocina";
import MenuViewAdmkLaundry from "./componentsTheLaundry/admKitPages/MenuViewAdmk";
import PedidosAdmKLaundry from "./componentsTheLaundry/admKitPages/PedidosAdmK";
import PerfilAdmnKLaundry from "./componentsTheLaundry/admKitPages/PerfilAdmK";
import LayoutUsuario from "./components/organisms/LayoutUsuario";
import Ingresar from "./components/pages/Ingresar";
import HomePageLaundry from "./componentsTheLaundry/pages/HomePage";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import HistorialPedidosUser from "./components/pages/HistorialPedidos";
import LayoutUsuarioTL from "./componentsTheLaundry/Organisms/LayoutUsuarioTL";
import RegisterTL from "./componentsTheLaundry/pages/RegisterTL";
import GuiaTL from "./componentsTheLaundry/pages/GuiaTL";
import HistorialPedidosUserTL from "./componentsTheLaundry/pages/HistorialPedidosTL";
import ConfigurationTL from "./componentsTheLaundry/pages/ConfigurationTL";
import IngresarTL from "./componentsTheLaundry/pages/IngresarTL";
import CajaAdmins from "./components/adminPages/CajaAdmins";
import CortesDeCaja from "./components/cajaPages/CortesDeCaja";
import CajaAdminsTL from "./componentsTheLaundry/adminPages/CajaAdmins";
import CortesDeCajaTL from "./componentsTheLaundry/cajaPages/CortesDeCaja";
import NotFound from "./components/pages/NotFound";
import PerfilAdmin from "./components/adminPages/PerfilAdmin";
import Cancelaciones from "./components/adminPages/Cancelaciones";
import HistorialDeCancelaciones from "./components/adminPages/HistorialDeCancelaciones";

const stripePromise = loadStripe('pk_test_51QS5gBJDXpZelMrvgwy41fpADNIfiKmdfXf6i9QKo7wUDltEcOsuDUYsJpYSsI0PMFyyaFLhdocQdZiwAL5DCM5c001gnhj26g');

function App() {
  return (
    <Elements stripe={stripePromise}>
      <SessionProvider>
        <ProductProvider>
            <Routes>
              {/* General */}
              <Route path="/" element={<HomePage/>}/>
              <Route path="/menu" element={<Menu/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/about" element={<AboutPage/>}/>
              <Route path="/ingresar" element={<Ingresar/>}/>
              <Route path="/register" element={<Register/>}/>
              <Route path="/menu/:kitchenId" element={<MenuProducts/>}/>
              {/* Usuario */}
              <Route path="/guia" element={<LayoutUsuario><Guia/></LayoutUsuario>}/>
              <Route path="/home" element={<LayoutUsuario><HomeUser/></LayoutUsuario>}/>
              <Route path="/config" element={<LayoutUsuario><Configuration/></LayoutUsuario>}/>
              <Route path="/MisPedidos" element={<LayoutUsuario><HistorialPedidosUser/></LayoutUsuario>}/>
              {/* Administrador */}
              <Route path="/users" element={<Layout><Users/></Layout>}/>
              <Route path="/Cancelaciones" element={<Layout><Cancelaciones/></Layout>}/>
              <Route path="/HistorialCancelaciones" element={<Layout><HistorialDeCancelaciones/></Layout>}/>
              <Route path="/cajas" element={<Layout><Cajas/></Layout>}/>
              <Route path="/barAdmin" element={<Layout><Bar/></Layout>}/>
              <Route path="/Admins" element={<Layout><Admins/></Layout>}/>
              <Route path="/meseros" element={<Layout><Meseros/></Layout>}/>
              <Route path="/kitchens" element={<Layout><Kitchens/></Layout>}/>
              <Route path="/PerfilAdmin" element={<Layout><PerfilAdmin/></Layout>}/>
              <Route path="/almacenes" element={<Layout><Almacenes/></Layout>}/>
              <Route path="/PedidosAdmin" element={<Layout><PedidosAdmin/></Layout>}/>
              <Route path="/CajaAdministrador" element={<Layout><CajaAdmins/></Layout>}/>
              <Route path="/UsuariosCocinas" element={<Layout><KitchensUsers/></Layout>}/>
              <Route path="/barAdmin/:kitchenId" element={<Layout><BarDetail/></Layout>}/>
              <Route path="/kitchens/menu/:kitchenId" element={<Layout><MenuPage/></Layout>}/>
              <Route path="/kitchens/:kitchenId" element={<Layout><KitchenDetail/></Layout>}/>
              <Route path="/barAdmin/menu/:kitchenId" element={<Layout><MenuPageBar/></Layout>}/>
              <Route path="/UsuariosAdminCocinas" element={<Layout><AdminsKitchensUsers/></Layout>}/>
              {/* Administrador de cocina */}
              <Route path="/pedidos" element={<LayoutCocina><PedidosAdmK/></LayoutCocina>}/>
              <Route path="/menuAdmin" element={<LayoutCocina><MenuViewAdmk/></LayoutCocina>}/>
              <Route path="/perfilCocina" element={<LayoutCocina><PerfilAdmnK/></LayoutCocina>}/>
              {/* Caja */}
              <Route path="/caja" element={<LayoutCaja><CajaMain/></LayoutCaja>}/>
              <Route path="/CortesDeCaja" element={<LayoutCaja><CortesDeCaja/></LayoutCaja>}/>
              <Route path="/historialPedidos" element={<LayoutCaja><HistorialPedidos/></LayoutCaja>}/>
              {/* General Laundry */}
              <Route path="/TheLaundry/login" element={<LoginLaundry/>}/>
              <Route path="/TheLaundry/ingresar" element={<IngresarTL/>}/>
              <Route path="/TheLaundry/register" element={<RegisterTL/>}/>
              {/* Usuario Laundry */}
              <Route path="/TheLaundry/guia" element={<LayoutUsuarioTL><GuiaTL/></LayoutUsuarioTL>}/>
              <Route path="/TheLaundry" element={<LayoutUsuarioTL><HomePageLaundry/></LayoutUsuarioTL>}/>
              <Route path="/TheLaundry/config" element={<LayoutUsuarioTL><ConfigurationTL/></LayoutUsuarioTL>}/>
              <Route path="/TheLaundry/MisPedidos" element={<LayoutUsuarioTL><HistorialPedidosUserTL/></LayoutUsuarioTL>}/>
              {/* Administrador Laundry */}
              <Route path="/TheLaundry/users" element={<LayoutLaundry><UsersLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/cajas" element={<LayoutLaundry><CajasLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/CajaAdministrador" element={<LayoutLaundry><CajaAdminsTL/></LayoutLaundry>}/>
              <Route path="/TheLaundry/barAdmin" element={<LayoutLaundry><BarLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/Admins" element={<LayoutLaundry><AdminsLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/meseros" element={<LayoutLaundry><MeserosLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/kitchens" element={<LayoutLaundry><KitchensLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/almacenes" element={<LayoutLaundry><AlmacenesLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/PedidosAdmin" element={<LayoutLaundry><PedidosAdminLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/UsuariosCocinas" element={<LayoutLaundry><KitchensUsersLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/barAdmin/:kitchenId" element={<LayoutLaundry><BarDetailLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/kitchens/menu/:kitchenId" element={<LayoutLaundry><MenuPageLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/kitchens/:kitchenId" element={<LayoutLaundry><KitchenDetailLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/barAdmin/menu/:kitchenId" element={<LayoutLaundry><MenuPageBarLaundry/></LayoutLaundry>}/>
              <Route path="/TheLaundry/UsuariosAdminCocinas" element={<LayoutLaundry><AdminsKitchensUsersLaundry/></LayoutLaundry>}/>
              {/* Administrador de cocina Laundry */}
              <Route path="/TheLaundry/pedidos" element={<LayoutCocinaLaundry><PedidosAdmKLaundry/></LayoutCocinaLaundry>}/>
              <Route path="/TheLaundry/menuAdmin" element={<LayoutCocinaLaundry><MenuViewAdmkLaundry/></LayoutCocinaLaundry>}/>
              <Route path="/TheLaundry/perfilCocina" element={<LayoutCocinaLaundry><PerfilAdmnKLaundry/></LayoutCocinaLaundry>}/>
              {/* Caja Laundry */}
              <Route path="/TheLaundry/caja" element={<LayoutCajaLaundry><CajaMainLaundry/></LayoutCajaLaundry>}/>
              <Route path="/TheLaundry/CortesDeCaja" element={<LayoutCajaLaundry><CortesDeCajaTL/></LayoutCajaLaundry>}/>
              <Route path="/TheLaundry/historialPedidos" element={<LayoutCajaLaundry><HistorialPedidosLaundry/></LayoutCajaLaundry>}/>
              {/* Ruta para manejar páginas no encontradas */}
              <Route path="*" element={<NotFound/>} />
            </Routes>
            <ToastContainer
              position="bottom-center" autoClose={3000} hideProgressBar={false} stacked newestOnTop={false}
              closeOnClick={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"
              />
        </ProductProvider>
      </SessionProvider>
    </Elements>
  );
}

export default App;
