import React from "react";
import "./styles.css";

const Button = ({ text, onClick, isFilled, largeMargin, width="w-64", ...rest }) => {
  const buttonClass = `button ${width} text-white h-12 rounded-full${
    isFilled ? "bg-blue-500 text-white" : "bg-transparent "
  } ${largeMargin ? "border-4 border-teal-300 hover:bg-teal-300 hover:text-black hover:text-[#FCFF5A] " : "border border-white"} 
    px-4 py-2 rounded transition duration-300 ease-in-out`;

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      {...rest}
    >
      {text}
    </button>
  );
};

export default Button;
