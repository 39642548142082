import React, { useEffect, useState } from "react";
import { GET_FOR_CUT, UPDATE_ORDER } from "../../apiTheLaundry/apiCaja";
import DataTable from "react-data-table-component";
import { PulseLoader } from "react-spinners";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import RealizarCorte from "./RealizarCorte";

const CorteDeCaja = ({ refetchCortes, closeCortes }) => {
    const [dataOrders, setDataOrders] = useState();
	const [loading, setLoading] = useState(false);
    const {openModal, isOpen, closeModal} = useModal();

	const fetchDataOrders = async () => {
		try {
			setLoading(true);
			const ordersData = await GET_FOR_CUT();
			setDataOrders(ordersData);
		} catch (err) {
			console.error(err);
			setDataOrders([]);
		} finally {
			setLoading(false);
		}
	};

    const updateAllOrders = async () => {
        if (!dataOrders || dataOrders?.length === 0) return;
    
        const updatedStatus = { status_caja: "Corte" };
        setLoading(true);
    
        try {
            const updatePromises = dataOrders.map((order) => 
                UPDATE_ORDER(order.id, updatedStatus)
            );
            await Promise.all(updatePromises);

            await fetchDataOrders();
        } catch (error) {
            console.error("Error al actualizar las órdenes:", error);
        } finally {
            setLoading(false);
        }
    };
    

    const getTotalEfectivo = () => {
        return dataOrders?.reduce((total, order) => total + (Number(order.total_efectivo) || 0), 0);
    };
    
    const getTotalTarjeta = () => {
        return dataOrders?.reduce((total, order) => total + (Number(order.total_tarjeta) || 0), 0);
    };
    
    const getTotalGeneral = () => {
        return dataOrders?.reduce((total, order) => total + (Number(order.total_general) || 0), 0);
    };    

    useEffect(() => {
		fetchDataOrders();
	}, []);

    return(
        <div>
            <DataTable
                data={dataOrders}
                columns={columns}
                progressPending={loading}
                progressComponent={<PulseLoader size={100}/>}
            />
            <div className="flex flex-row items-center justify-end space-x-4">
                <LargeText text={`Efectivo: $${getTotalEfectivo()} MX`} colorText="text-black" textBold/>
                <LargeText text={`Tarjeta: $${getTotalTarjeta()} MX`} colorText="text-black" textBold/>
                <LargeText text={`Total: $${getTotalGeneral()} MX`} colorText="text-black" textBold/>
                <ButtonForm text="Realizar corte" bgColor="bg-violet-600" width="w-32" onClick={openModal} disabled={dataOrders?.length <= 0 ? true : false || loading} />
            </div>
            {isOpen &&
                <ModalAdmin title="Realizar corte" onClose={closeModal}>
                    <RealizarCorte 
                        onClose={closeModal} 
                        totalEfectivo={getTotalEfectivo()} 
                        totalTarjeta={getTotalTarjeta()} 
                        totalGeneral={getTotalGeneral()} 
                        refetchCortes={refetchCortes}
                        closeCortes={closeCortes}
                        updateAllOrders={updateAllOrders}
                    />
                </ModalAdmin>
            }
        </div>
    )
}

export default CorteDeCaja;

const columns = [
    {name:"ID", selector: row => row.id},
    {name:"Total efectivo", selector: row => row.total_efectivo || 0},
    {name:"Total tarjeta", selector: row => row.total_tarjeta || 0},
    {name:"Total", selector: row => row.total_general},
];
