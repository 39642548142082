import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Titulo from "../atoms/Titulo";
import LargeText from "../atoms/LargeText";
import contenedor from "../assets/Imagenes/OIG4.jpeg";

const HomePage = () => {
	const navigate = useNavigate();
	
	useEffect(() => {
		navigate("/about");
		document.title = "Contenedor";
	}, [navigate]);

	return (
		<div
			style={{
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${contenedor})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
			}}
			className="relative h-screen"
		>
			<div className="h-[80%] w-full flex flex-col text-center justify-center px-4 md:px-0">
				<Titulo Letras="CONTENEDOR" />
				<div className="mt-12">
					<LargeText
						text="Es más que una comida, es una experiencia sensorial inolvidable."
						size="text-xl md:text-3xl"
					/>
					<div className="w-full flex flex-col md:flex-row text-center justify-center ">
						<LargeText
							size="text-lg md:text-2xl"
							text="¡Estoy seguro de que querrás saber más y vivirlo por ti mismo!&nbsp;"
							colorText="text-yellow-300"
						/>
						<LargeText
							size="text-lg md:text-2xl"
							text="¿Te animas?"
							colorText="text-yellow-300"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
