import React, { useState } from "react";
import ButtonForm from "./ButtonForm";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import Modal from "../molecules/Modal";
import useModal from "../Hooks/useModal";
import EditarDireccion from "../molecules/Guia/EditarDireccion";
import ModalConfirm from "../molecules/ModalConfirm";
import { UPDATE_ADDRESS } from "../../apiUser";
import { useSession } from "../../context/SessionContext";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";

const RadioButtonsList = ({ options, refetch, addressData }) => {
    const { user } = useSession();
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openModalMain, isOpen: isOpenMain, closeModal: closeModalMain } = useModal();
    const { openModal: openModalDelete, isOpen: isOpenDelete, closeModal: closeModalDelete } = useModal();
    const [selectedOption, setSelectedOption] = useState();

    const handleSelect = (selected) => {
        setSelectedOption(selected);
        openModal();
    }

    const handleSelectMain = (selected) => {
        setSelectedOption(selected);
        openModalMain();
    }

    const handleSelectDelete = (selected) => {
        setSelectedOption(selected);
        openModalDelete();
    }

    const handleSetMainAddress = async() => {
        try {
            const updatedAddresses = addressData[0]?.direcciones.map(address => {
                if (address.id === selectedOption.id) {
                    return { ...address, main: true };
                } else {
                    return { ...address, main: false };
                }
            });
            const updatedAddressPayload = { address: updatedAddresses };
            await UPDATE_ADDRESS(user.id, updatedAddressPayload);
            toast.success("Dirección principal actualizada correctamente.");
            refetch(); 

        } catch (error) {
            console.error("Error al actualizar la dirección principal", error);
            toast.error("Hubo un error al actualizar la dirección principal.");
        }
    };

    const handleDelete = async () => {
        try {
            const updatedAddresses = addressData[0]?.direcciones.filter(address => address.id !== selectedOption.id);
            
            const updatedAddressPayload = { address: updatedAddresses };
            
            await UPDATE_ADDRESS(user.id, updatedAddressPayload);
            toast.success("Dirección eliminada correctamente.");
            refetch(); 
        } catch (error) {
            console.error("Error al eliminar la dirección", error);
            toast.error("Hubo un error al eliminar la dirección.");
        }
    }

    const handleAlready = () => {
        toast.success("Ya es tu dirección principal")
    }

    return (
        <div className="w-full flex flex-col text-white">
            {options?.map((option) => (
                <div key={option.value} className="bg-[#242424a0] px-4 py-1 my-1 rounded-md space-x-4 flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center space-x-4">
                        <label htmlFor={option.id}>
                            {option.calle }, 
                            {` Ext. #${option?.numero_exterior}, `} 
                            {option.numero_interior && `Int. #${option?.numero_interior}, `} 
                            {option.colonia},
                            {` CP.${option.codigo_postal}`}, {" "}
                            {option.municipio}
                            {`, ${option?.tag}`}
                        </label>
                    </div>
                    <div className="flex flex-row space-x-2">
                        <ButtonForm 
                            bgColor="bg-black"
                            text={<FaCheck className={option.main === true ? "text-green-400" : "text-white"} size={33} />} 
                            width="w-12"
                            onClick={option.main === true ? () => handleAlready() : () => handleSelectMain(option)}
                        />
                        <ButtonForm bgColor="bg-blue-600" text={<MdEdit size={33} />} width="w-12" onClick={ () => handleSelect(option)} />
                        <ButtonForm bgColor="bg-red-500" text={<MdDeleteOutline size={33} />} onClick={ () => handleSelectDelete(option)} width="w-12"/>
                    </div>
                </div>
            ))}
            {isOpen &&
                <Modal title="Editar dirección" onClose={closeModal} height="h-auto">
                    <EditarDireccion addressData={addressData} onClose={closeModal} refetch={refetch} selected={selectedOption} />
                </Modal>
            }
            {isOpenMain &&
                <ModalConfirm onClose={closeModalMain} onConfirm={() => handleSetMainAddress()} />
            }
            {isOpenDelete &&
                <ModalConfirm onClose={closeModalDelete} onConfirm={() => handleDelete()} />
            }
        </div>
    );
};

export default RadioButtonsList;
