import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: { "Content-Type": "application/json", Platform: "web" },
});

export const CREATE_ORDER = async (orderData) => {
	try {
		const response = await api.post("/api/orderDelivery", orderData);
		return response.data;
	} catch (error) {
		console.error("Error create order data:", error);
		throw error;
	}
};

export const UPDATE_ORDER_DELIVERY = async (orderId, updatedOrder) => {
	try {
		const response = await api.put(
			`/api/orderDelivery/${orderId}`,
			updatedOrder
		);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar el pedido", error);
	}
};

export const CREATE_ORDER_HISTORY = async (orderData) => {
	try {
		const response = await api.post("/api/orderHistory", orderData);
		return response.data;
	} catch (error) {
		console.error("Error al crear la orden:", error);
		throw error;
	}
};

export const SEND_NOTIFICATION = async (orderData) => {
	try {
		const payload = { id_kitchen: orderData.id_cocina };
		const response = await api.post(
			"/api/notificationWaiter",
			payload
		);
		return response;
	} catch (error) {
		console.error("Error al procesar la notificacion", error);
	}
};

export const GET_USER_ID = async (id) => {
	try {
		const response = await api.get(`/api/userExternal/${id}`);
		if (!response.data || !response.data.id) {
			throw new Error("Usuario no encontrado");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 404) {
			throw new Error("No existe el usuario con el ID proporcionado");
		}
		throw new Error("Error al obtener la información del usuarios");
	}
};

export const LOGOUT_USER = async () => {
	try {
		const response = await api.post("/api/logoutExternal");
		return response;
	} catch (error) {
		console.error("Error al cerrar sesión", error);
		throw error;
	}
};

export const UPDATE_USER = async (id, data) => {
	try {
		const response = await api.put(`/api/userExternal/${id}`, data);
		return response.data;
	} catch (error) {
		console.error("Error al atualizar el usuarios", error);
		throw error;
	}
};

export const GET_ADDRESS_BY_USER = async (id_user) => {
	try {
		const response = await api.get(`/api/address/${id_user}`);
		return response.data;
	} catch (error) {
		console.error("Error ", error);
		throw error;
	}
};

export const CREATE_ADDRESS = async (data) => {
	try {
		const response = await api.post(`/api/address`, data);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar la dirección", error);
		throw error;
	}
};

export const UPDATE_ADDRESS = async (id_user, address) => {
	try {
		const response = await api.put(`/api/address/${id_user}`, address);
		return response.data;
	} catch (error) {
		console.error("Error ", error);
		throw error;
	}
};

export const GET_ACTIVE_KITCHEN = async () => {
	try {
		const currentTime = new Date().toTimeString().split(" ")[0];
		const response = await api.get("/api/kitchensActive", {
			params: { currentTime },
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching kitchen data:", error);
		throw error;
	}
};

export const GET_ORDERS_BY_EXTERNAL_USER = async (id_usuarioExterno) => {
	try {
		const response = await api.get(`/api/orderHistoryExternalUser/${id_usuarioExterno}`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener las órdenes del usuario externo:", error);
		throw error;
	}
};

export const CREATE_PAYMENT_INTENT = async (total) => {
	try {
		const response = await api.post("/api/createPayment", { total });
		return response.data; // { clientSecret }
	} catch (error) {
		console.error("Error al crear el PaymentIntent:", error);
		throw error;
	}
};