import React, { useState, useEffect } from "react";
import { getKitchen } from "../../api";
import { GET_ADMINS, GET_CANCELLATIONS, GET_WAITERS } from "../../apiAdmin";
import DataTable from "react-data-table-component";
import moment from "moment/moment";

const HistorialDeCancelaciones = () => {
    const [cocinas, setCocinas] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [meseros, setMeseros] = useState([]);
    const [selectedKitchen, setSelectedKitchen] = useState("");
    const [cancellations, setCancellations] = useState([]);

    const fetchKitchens = async () => {
        try {
            const adminsData = await GET_ADMINS();
            setAdmins(adminsData);
            const cocinasData = await getKitchen();
            setCocinas(cocinasData);
            const meserosData = await GET_WAITERS();
            setMeseros(meserosData);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchCancellations = async () => {
        try {
            const cancellationsData = await GET_CANCELLATIONS();
            setCancellations(cancellationsData);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchKitchens();
    }, []);

    useEffect(() => {
        fetchCancellations();
    }, []);

    const getWaiterName = (idMesero) => {
        const waiter = meseros?.find((mesero) => mesero.id === idMesero);
        return waiter ? waiter?.user.username : "Desconocido";
    };

    const getKitchenName = (idCocina) => {
        const kitchen = cocinas?.find((cocina) => cocina.id === idCocina);
        return kitchen ? kitchen.nombre : "Desconocido";
    };

    const getAdminName = (idAdmin) => {
        const admin = admins?.find((admin) => admin.id === idAdmin);
        return admin ? admin.nombre : "Desconocido";
    };

    const columns = [
        { name: "ID", selector: (row) => row.id },
        { name: "Razon", selector: (row) => row.razon },
        { name: "Producto", selector: (row) => row.producto },
        { name: "Precio", selector: (row) => `$${row.precio}MX` },
        { name: "Cocina", selector: (row) => getKitchenName(row.id_cocina) },
        { name: "Admin", selector: (row) => getAdminName(row.id_admin) },
        { name: "Mesero", selector: (row) => getWaiterName(row.id_mesero) },
        { name: "Fecha", selector: (row) => moment(row.createdAt).format("DD/MM/YY HH:mm") },
    ];

    const filteredCancellations = cancellations.filter((cancellation) =>
        selectedKitchen ? cancellation.id_cocina === parseInt(selectedKitchen) : true
    );

    return (
        <div className="border-2 border-black p-4 rounded-lg shadow-lg">
            <div className="mb-4">
                <label htmlFor="kitchenFilter" className="mr-2 font-bold">
                    Filtrar por cocina:
                </label>
                <select
                    id="kitchenFilter"
                    className="border rounded py-2 px-4 border-violet-300"
                    value={selectedKitchen}
                    onChange={(e) => setSelectedKitchen(e.target.value)}
                >
                    <option value="">Todas las cocinas</option>
                    {cocinas.map((cocina) => (
                        <option key={cocina.id} value={cocina.id}>
                            {cocina.nombre}
                        </option>
                    ))}
                </select>
            </div>

            <DataTable data={filteredCancellations} columns={columns} />
        </div>
    );
};

export default HistorialDeCancelaciones;