import React from "react";
import { Field, ErrorMessage } from "formik";

const RegisterField = ({
    name = "",
    value = "",
    label = "",
    type="", 
    ...res
}) => {
    return( 
        <div className="w-full relative py-3">
            <Field 
                className="border-2 bg-transparent text-white border-white w-full p-2 text-center rounded-full focus:border-teal-300 focus:outline-none ring-0 disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-400" 
                name={name}
                placeholder={value}
                type={type}
                {...res}
            />
            <ErrorMessage name={name}>
            {(message) => (
                <div className="absolute right-0 text-sm text-teal-300">{message}</div>
            )}
            </ErrorMessage>
        </div>
    );
}

export default RegisterField;