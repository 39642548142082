import React from "react";
import { Formik, Form } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";
import moment from "moment";
import { FcSearch } from "react-icons/fc";

const FilterKD = ({ originalOrders, setDataOrders, today }) => {
    const handleSubmit = (values) => {
        const { fecha_inicio, fecha_fin, metodo_pago } = values;
        let filteredOrders = originalOrders;

            const start = moment(fecha_inicio).startOf('day');
            const end = moment(fecha_fin).endOf('day');
            filteredOrders = filteredOrders.filter(order => {
                const orderDate = moment(order.createdAt);
                return orderDate.isBetween(start, end, null, '[]');
            });

        if (metodo_pago === "Todos" ) {
            filteredOrders = filteredOrders.filter(order => order.metodo_pago !== metodo_pago)
        } else {
            filteredOrders = filteredOrders.filter(order => order.metodo_pago === metodo_pago);
        }

        setDataOrders(filteredOrders);
    };

    const validate = (values) => {
        const errors = {};
        if (!values.fecha_inicio) {
            errors.fecha_inicio = "Campo requerido";
        } 
        if (!values.fecha_fin) {
            errors.fecha_fin = "Campo requerido";
        } 
        if (values.fecha_fin < values.fecha_inicio) {
            errors.fecha_fin = "La fecha de inicio es mayor a la de fin";
        }
        return errors;
    };

    const options = [
        { label: "Todos", value: "Todos" },
        { label: "Tarjeta", value: "Tarjeta" },
        { label: "Efectivo", value: "Efectivo" },
        { label: "Mixto", value: "Mixto" },
    ];

    return (
        <div>
            <Formik
                initialValues={{ fecha_inicio: today, fecha_fin: today, metodo_pago: options[0].value }}
                onSubmit={handleSubmit}
                validate={validate}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="w-full flex flex-wrap md:flex-nowrap md:flex-row sm:space-x-4 items-center">
                        <InputFFAdmin name="fecha_inicio" type="date" label="De:" />
                        <InputFFAdmin name="fecha_fin" type="date" label="A:" />
                        <InputFFAdmin name="metodo_pago" type="select" label="Método de pago" options={options} />
                        <ButtonForm text={<FcSearch size={30} />} width="w-40" bgColor="bg-violet-300 bg-opacity-60" type="submit" />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default FilterKD;
