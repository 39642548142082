import React from "react";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import { IoIosCloseCircle } from "react-icons/io";

const Modal = ({width="w-full sm:w-[75%]",height="h-[80%]" ,onClose , Button="", onClick, title="", children}) =>{
    const divClassname = `w-full sm:${width} h-full sm:${height} bg-black rounded-lg p-4 overflow-auto z-50`;
    
    return(
        <div className="fixed bottom-0 left-0 h-full w-full bg-teal-300 bg-opacity-30 flex justify-center items-center z-30">
            <div className={divClassname}>
                <div className="flex flex-row justify-between items-center">
                    <LargeText text={title} textBold size="text-lg sm:text-4xl" />
                    <div className="flex flex-row items-center">
                        {Button && <ButtonForm text={Button} onClick={onClick} width="w-16 ms:w-32 mr-4"/>}
                        <IoIosCloseCircle className="text-white cursor-pointer h-6 sm:h-10 w-6 sm:w-10"  onClick={onClose}/>
                    </div>
                </div>
                <div className="w-full h-.5 border-2 border-white my-2 " />
                {children}
            </div>
        </div>
    )
}

export default Modal;