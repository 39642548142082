import React, { useState } from "react";
import useModal from "../../Hooks/useModal";
import ModalConfirm from "../../molecules/ModalConfirm";
import { UPDATE_ADDRESS } from "../../../apiUser";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { useSession } from "../../../context/SessionContext";
import ButtonForm from "../../atoms/ButtonForm";

const CambiarDireccion = ({ options, refetch, addressData, onClose }) => {
    const { user } = useSession();
    const { openModal: openModalMain, isOpen: isOpenMain, closeModal: closeModalMain } = useModal();
    const [selectedOption, setSelectedOption] = useState();

    const handleSelectMain = (selected) => {
        setSelectedOption(selected);
        openModalMain();
    }

    const handleSetMainAddress = async() => {
        try {
            const updatedAddresses = addressData[0]?.direcciones.map(address => {
                if (address.id === selectedOption.id) {
                    return { ...address, main: true };
                } else {
                    return { ...address, main: false };
                }
            });
            const updatedAddressPayload = { address: updatedAddresses };
            await UPDATE_ADDRESS(user.id, updatedAddressPayload);
            toast.success("Dirección principal actualizada correctamente.");
            closeModalMain();
            onClose();
            refetch(); 
        } catch (error) {
            console.error("Error al actualizar la dirección principal", error);
            toast.error("Hubo un error al actualizar la dirección principal.");
        }
    };

    const handleAlready = () => {
        toast.success("Ya es tu dirección principal")
    }

    return (
        <div className="w-full flex flex-col text-white">
            {options?.map((option) => (
                <div key={option.value} className="bg-[#242424a0] px-4 py-1 my-1 rounded-md space-x-4 flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center space-x-4">
                        <label htmlFor={option.id}>
                            {option.calle }, 
                            {` Ext. #${option?.numero_exterior}, `} 
                            {option.numero_interior && `Int. #${option?.numero_interior}, `} 
                            {option.colonia},
                            {` CP.${option.codigo_postal}`}, {" "}
                            {option.municipio}
                            {`, ${option?.tag}`}
                        </label>
                    </div>
                    <div className="flex flex-row space-x-2">
                        <ButtonForm
                            bgColor="bg-black"
                            text={<FaCheck className={option.main === true ? "text-green-400" : "text-white"} size={33} />} 
                            width="w-12"
                            onClick={option.main === true ? () => handleAlready() : () => handleSelectMain(option)}
                        />
                    </div>
                </div>
            ))}
            {isOpenMain &&
                <ModalConfirm onClose={closeModalMain} onConfirm={() => handleSetMainAddress()} />
            }
        </div>
    );
};

export default CambiarDireccion;
