import React, { useState } from "react";
import { CREATE_CAJA_START } from "../../apiTheLaundry/apiAdmin";
import { toast } from "react-toastify";
import { Formik } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";

const OpenCaja = ({ refetch, onClose }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setLoading(true);
            await CREATE_CAJA_START(values); 
            toast.success("Caja iniciada correctamente ✅🍽");
            refetch();
            onClose();
        } catch (error) {
            toast.error("Error al iniciar la caja ❌🍽");
            console.error(error);
        } finally {
            setLoading(false);
        }
        setSubmitting(false);
    };

    const initialValues = { 
        dinero_caja:"", 
        status: "", 
    }

    const validate = (values) => {
		const errors = {};
        if (!values.dinero_caja) { errors.dinero_caja = "Campo requerido"; }
        else if (values.dinero_caja < 0) { errors.dinero_caja = "No puedes iniciar en negativo" }
		if (!values.status) { errors.status = "Campo requerido"; }
		return errors;
	};

    const options = [
        {label: "Cerrada", value: false},
        {label: "Abierta", value: true},
    ]

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className="w-full flex flex-row space-x-4">
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="dinero_caja" label="Dinero para inciar" type="number" placeholder="$" />
                        </div>
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="status" label="Estatus de la caja" type="select" options={options} />
                        </div>
                    </div>
                    <ButtonForm
                        text={loading ? "Cargando" : "Guardar cambios"} type="submit"
                        bgColor={loading ? "bg-gray-300" : "bg-violet-300"} width="w-1/3 ml-auto mt-6" 
                    />
                </form>
                )}
            </Formik>
        </div>
    )
}

export default OpenCaja;