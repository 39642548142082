import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useModal from "../Hooks/useModal";
import LargeText from "../atoms/LargeText";
import Modal from "../molecules/Modal";
import ModalConfirm from "../molecules/ModalConfirm";
import Header from "../Organisms/Header";
import H2Custom from "../atoms/H2Custom";
import { useSession } from "../../context/SessionContext";
import { GET_USER_ID, LOGOUT_USER } from "../../apiTheLaundry/apiUser";
import FotoUsuarioTL from "../atoms/FotoUsuarioTL";
import PerfilTL from "../Organisms/Configuration/PerfilTL";
import DireccionesTL from "../Organisms/Configuration/DireccionesTL";

const ConfigurationTL = () => {
    const { user, setUser } = useSession();
    const [loading, setLoading] = useState();
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openUpload, isOpen: isOpenUpload, closeModal: closeUpload } = useModal();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const section = searchParams.get("section") || "perfil";
    const [activeSection, setActiveSection] = useState(section);

    useEffect(() => {
        document.title = "Configuración - The Laundry";
    }, []); 

    useEffect(() => {
        const scrollToConfig = () => {
            const section = document.getElementById("#");
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        };
        scrollToConfig();
        setActiveSection(section);
    }, [section, location.hash]);
    
    const fetchUser = useCallback(async () => {
        try {
            const dataUser = await GET_USER_ID(user?.id)
            setUser(dataUser);
        } catch (error) {
            toast.error("Error al obtener el usuario");
        }
    }, [setUser, user?.id]);
        
    useEffect(() => {
        fetchUser();
    }, [fetchUser, setUser]);

    const handleNavigation = (section) => {
        navigate(`/TheLaundry/config?section=${section}`);
    };

    const handleSignOut = async () => {
        try {
            await LOGOUT_USER();
            toast.success("Sesión cerrada correctamente");
            navigate('/TheLaundry');
        } catch (error) {
            console.error("Error al cerrar sesión ", error);
            toast.error("Error al cerrar sesión");
            closeModal();
        }
    };

    return (
        <div id="top">
            <Header />
            <div className="h-fit sm:h-screen w-screen py-4 px-4 pt-16 sm:pt-14 flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0" id="#">
                <section className="w-full sm:w-1/4 flex">
                    <div className="w-full sm:bg-gray-700 rounded-md flex flex-row sm:flex-col items-center overflow-auto space-x-4 sm:space-x-0">
                        <div className="w-full px-2 border-b-2 border-white h-[25%] items-center hidden sm:flex rounded-md">
                            {user?.imagen ? (
                                <img src={user?.imagen} className="h-16 w-16 rounded-full border-2 cursor-pointer" alt="Imagen" onClick={openUpload} />
                            ) : (
                                <button 
                                    className="h-16 w-16 rounded-full border-2 flex text-center text-white justify-center items-center text-2xl" 
                                    onClick={openUpload}
                                >
                                    {user?.nombre?.[0]}{user?.apellido?.[0]}
                                </button>
                            )}
                            <div className="pl-4 flex flex-col">
                                <H2Custom text="Mi configuración" textBold />
                                <LargeText text={user?.nombre} textBold size="text-2xl" colorText="text-teal-300" />
                            </div>
                        </div>
                        <div onClick={() => handleNavigation('perfil')} className={` ${activeSection === "perfil" ? "bg-green-600 sm:bg-transparent" : "bg-transparent border-2 sm:border-0 border-green-600"} rounded-md min-w-24 sm:w-full sm:border-b-2 h-12 sm:h-[12.5%] px-4 sm:border-white flex justify-center sm:justify-start items-center cursor-pointer`}>
                            <LargeText text="Perfil" textBold />
                        </div>
                        <div className={`${activeSection === "direcciones" ? "bg-green-600 sm:bg-transparent" : "bg-transparent border-2 sm:border-0 border-green-600"} rounded-md min-w-56 w-full sm:border-b-2 px-4 sm:border-white h-12 sm:h-[12.5%] flex items-center cursor-pointer justify-center sm:justify-start`} onClick={() => handleNavigation('direcciones')}>
                            <LargeText text="Direcciones guardadas" textBold />
                        </div>
                        <div className={`${isOpen ? "bg-green-600 sm:bg-transparent" : "bg-transparent border-2 sm:border-0 border-green-600"} rounded-md min-w-36 w-full h-12 sm:h-[12.5%] px-4 flex items-center cursor-pointer justify-center sm:justify-start`} onClick={openModal}>
                            <LargeText text="Cerrar sesión" textBold />
                        </div>
                    </div>
                </section>
                <section className="h-full w-full sm:w-3/4 flex sm:pr-8">
                    {activeSection === 'perfil' && 
                        <PerfilTL user={user} loading={loading} setLoading={setLoading} refetch={fetchUser} />
                    }
                    {activeSection === 'direcciones' && 
                        <DireccionesTL user={user} loading={loading} setLoading={setLoading} />
                    }
                </section>
            </div>
            {isOpen && <ModalConfirm onClose={closeModal} onConfirm={handleSignOut} />}
            {isOpenUpload && 
                <Modal title={`${user?.imagen === null ? ("Subir foto") : ("Actualizar foto") }`} onClose={closeUpload} >
                    <FotoUsuarioTL user={user} />
                </Modal>
            }
        </div>
    );
};

export default ConfigurationTL;