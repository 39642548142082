import React, { useEffect, useState } from "react";
import contenedor from "../../components/assets/Imagenes/OIG4.jpeg";
import RegisterStep3TL from "../Organisms/Register/RegisterStep3TL";

const RegisterTL = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Registro - The Laundry";
    }, []); 
    
    return(
        <div 
            style={{
                backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, .8)), url(${contenedor})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
            className="w-full h-full bg-black flex justify-center items-center flex-col"
        >
            <RegisterStep3TL 
                loading={loading} setLoading={setLoading} 
            /> 
        </div>
    );
}

export default RegisterTL;