import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { IoIosCloseCircle } from "react-icons/io";
import Circle from "../../atoms/Circle";
import { FieldArray, Formik } from "formik";
import Contador from "../../atoms/Contador";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import InputFFProd from "../../atoms/InputFFProd";
import InputFFProdEA from "../../atoms/InputFFprodEA";
import { useProducts } from "../../../context/ProductContext";
import { GET_ADICIONALES_BY_PRODUCT, GET_EXTRAS_BY_PRODUCT } from "../../../apiTheLaundry/apiAdmin";

const ModalProduct = ({isEditing, item, onClose, cocina }) => {
    const { addToCart, groupedByCategory, updateCart, calculateTotal } = useProducts();
	const [extras, setExtras] = useState([]);
    const [additionals, setAdditionals] = useState([]);
    const [totalProduct, setTotalProduct] = useState(0);
    const [countProducts, setCountProducts] = useState(1);
    const [selectedExtras, setSelectedExtras] = useState({});
    const [selectedAdditionals, setSelectedAdditionals] = useState({});

	const fetchExtras = useCallback(async () => {
		if (isEditing) {
			setCountProducts(item?.quantity || 1);
			const normalizedExtras = Object.entries(item?.extras || {}).reduce((acc, [category, extra]) => {
				acc[category] = extra.id;
				return acc;
			}, {});
			setSelectedExtras(normalizedExtras);
		}
		try {
			const extrasData = await GET_EXTRAS_BY_PRODUCT(item.id);
			setExtras(extrasData);
		} catch (error) {
			console.error("Error fetching extras:", error);
			setExtras([]);
		}
	}, [item.id, item?.extras, item.quantity, isEditing]);	

	const fetchAditionals = useCallback(async () => {
		if (isEditing) {
			setCountProducts(item.quantity || 1);
			const adicionalesSeleccionados = {};
			item?.additionals?.Complemento?.forEach((adicional) => {
				if (!adicionalesSeleccionados[adicional?.categoria]) {
					adicionalesSeleccionados[adicional?.categoria] = [];
				}
				adicionalesSeleccionados[adicional?.categoria].push(adicional?.id);
			});
			setSelectedAdditionals(adicionalesSeleccionados);
		}
		try {
			const additionalsData = await GET_ADICIONALES_BY_PRODUCT(item.id);
			setAdditionals(additionalsData);
		} catch (error) {
			console.error("Error fetching extras:", error);
			setAdditionals([]);
		}
	},[item.id, isEditing, item.quantity, item?.additionals]);

	useEffect(() => {
		fetchExtras();
	}, [fetchExtras]);
	
	useEffect(() => {
		fetchAditionals();
	}, [fetchAditionals]);
	
	useEffect(() => {
		setTotalProduct(calculateTotal(item, countProducts));
	}, [ countProducts, selectedExtras, selectedAdditionals, item.precio, calculateTotal, item ]);

	const handleAddAndContinue = (values) => {
		const selectedExtrasCount = Object.keys(values.extras).length;
		const categoriesCount = Object.keys(extrasOrdenados).length;
	
		if (selectedExtrasCount !== categoriesCount) {
			toast.error(`Debes seleccionar exactamente ${categoriesCount} extras, uno por cada categoría.`);
			return;
		}
	
		const formattedExtras = Object.entries(values?.extras).reduce((acc, [category, id]) => {
			const extra = extras.find((item) => item.id === id);
			if (extra) {
				acc[category] = {
					id: extra.id,
					nombre: extra.nombre,
					categoria: extra.categoria,
					createdAt: extra.createdAt,
					updatedAt: extra.updatedAt,
					id_producto: extra.id_producto,
				};
			}
			return acc;
		}, {});
	
		const formattedAdditionals = Object.entries(values.adicionales).reduce((acc, [category, ids]) => {
			const items = additionals.filter((item) => ids.includes(item.id));
			acc[category] = items.map((additional) => ({
				id: additional.id,
				nombre: additional.nombre,
				categoria: additional.categoria,
				createdAt: additional.createdAt,
				updatedAt: additional.updatedAt,
				id_producto: additional.id_producto,
			}));
			return acc;
		}, {});
	
		if (countProducts === 0) {
			onClose();
		} else {
			const productData = {
				...item,
				quantity: countProducts,
				totalProduct: totalProduct,
				extras: formattedExtras,
				additionals: formattedAdditionals,
				comments: values.comments,
				uniqueId: isEditing ? item.uniqueId : uuidv4(),
			};
			if (isEditing) {
				updateCart(productData);
				toast.success("Carrito actualizado correctamente ✅ 🛒");
			} else {
				addToCart(productData);
				toast.success("Añadido al carrito correctamente ➕ 🛒");
			}
			onClose();
		}
	};
	
	const extrasOrdenados = groupedByCategory(extras);
	const adicionalesOrdenados = groupedByCategory(additionals);

    return (
        <main 
            className="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-black">
            <div
                className="w-full sm:w-[90%] h-full sm:h-[95%] rounded-lg overflow-auto"
            >
                <Formik
					enableReinitialize={true} 
                    initialValues={{ extras: selectedExtras || {}, adicionales: selectedAdditionals || [], comments: item.comments || "" }}
                    onSubmit={(values) => handleAddAndContinue(values)}
                >
                    {({ handleSubmit, values, setFieldValue }) => (
                        <form className="w-full h-full" onSubmit={handleSubmit}>
                            <header className="w-full h-fit sm:h-1/5 flex flex-col-reverse sm:flex-row items-center justify-around sm:justify-between text-center py-4 sm:py-0 px-0 sm:px-8">
                                <Circle cocina={cocina} Height="h-24" Width="aspect-square" selected />
                                <div>
                                    <LargeText text={`Cocina ${cocina.nombre}`} size="text-lg sm:text-5xl" colorText="text-teal-300" textBold />
                                    <LargeText text="Detalles del producto" size="text-sm sm:text-5xl" textBold={true} />
                                </div>
                                <IoIosCloseCircle size={30} className="text-white mb-4 sm:mb-0 cursor-pointer" onClick={onClose} />
                            </header>
                            <div className="flex-1 h-screen sm:h-[70%] w-full flex flex-col sm:flex-row overflow-auto">
                                <div className="w-full sm:w-1/2 h-1/2 sm:h-full flex flex-col justify-between p-6">
                                    <div className="w-full flex justify-between">
                                        <LargeText text={item.nombre} size="text-2xl sm:text-5xl" colorText="text-teal-300" textBold />
                                    </div>
                                    <div className="flex w-full h-full justify-center p-8">
                                        <img src={item.imagen} alt="Producto imagen" className="max-h-full max-w-full rounded-lg" />
                                    </div>
                                </div>
                                <div className="w-full sm:w-1/2 h-auto sm:h-full p-4 flex flex-col overflow-auto">
                                    <LargeText text={` $ ${item.precio} MX`} size="text-2xl" colorText="text-teal-300" textBold />
                                    <LargeText text={item.descripcion} textBold size="text-2xl" />
									<FieldArray name="extras">
									{({ form }) =>
										Object.entries(extrasOrdenados).map(([category, items]) => (
											<div key={category} className="mb-4 border-2 rounded-lg border-gray-700 p-1">
												<div className="mb-2 text-green-500 font-bold">{category}</div>
												{items.map((extra) => (
													<label key={extra.id} className="flex items-center space-x-2">
															<InputFFProdEA
																type="radio"
																name={`extras.${category}`}
																value={extra.id}
																label={extra.nombre}
																checked={form.values.extras[category] === extra.id}
																onChange={() => form.setFieldValue(`extras.${category}`, extra.id)}
															/>
														<span>{extra.nombre}</span>
													</label>
												))}
											</div>
										))
									}
								</FieldArray>
									<FieldArray name="adicionales">
									{({ form, push, remove }) =>
										Object.entries(adicionalesOrdenados).map(([category, items]) => (
										<div key={category} className="mb-4 border-2 rounded-lg border-gray-700 p-1">
											<div className="mb-2 text-green-500 font-bold">{category}</div>
											{items.map((additional) => (
											<label key={additional.id} className="flex items-center space-x-2">
												<InputFFProdEA
												type="checkbox"
												name={`adicionales.${category}`}
												value={additional.id}
												checked={form.values.adicionales[category]?.includes(additional.id)}
												label={additional.nombre}
												onChange={(e) => {
													const currentValues = form.values.adicionales[category] || [];
													if (e.target.checked) {
													form.setFieldValue(
														`adicionales.${category}`,
														[...currentValues, additional.id]
													);
													} else {
													form.setFieldValue(
														`adicionales.${category}`,
														currentValues.filter((id) => id !== additional.id)
													);
													}
												}}
												/>
												<span>{additional.nombre}</span>
											</label>
											))}
										</div>
										))
									}
									</FieldArray>
                                    <InputFFProd label="Comentarios" name="comments" type="text" />
                                </div>
                            </div>
							{isEditing ? (
								<section className="w-full h-fit sm:h-[10%] flex flex-col sm:flex-row">
									<div className="w-full sm:w-1/2 h-full px-8 flex items-center justify-center">
										<Contador selectedProduct={item} onCountChange={setCountProducts} />
									</div>
									<div className="w-full sm:w-1/2 h-full flex flex-col sm:flex-row justify-between items-center px-4 sm:pr-4 sm:space-x-4">
										<ButtonForm 
											bgColor={totalProduct <= 0 ? "bg-transparent" : "bg-teal-300" }
											textColor="" 
											text={totalProduct <= 0 ? "" : `Guardar $${totalProduct.toFixed(2)}`} 
											width="w-full sm:w-1/2" 
											type="submit"
											disabled={totalProduct <= 0}
										/>
									</div>
								</section>
							) : (
								<section className="w-full h-fit sm:h-[10%] flex flex-col sm:flex-row">
									<div className="w-full sm:w-1/2 h-full px-8 flex items-center justify-center">
										<Contador
											selectedProduct={item}
											initialCount={countProducts}
											onCountChange={setCountProducts}
										/>
									</div>
									<div className="w-full sm:w-1/2 h-full flex flex-col sm:flex-row justify-between items-center px-4 sm:pr-4 sm:space-x-4">
										<ButtonForm bgColor="bg-black" textColor="text-teal-300" text="Cerrar" width="border-2 border-teal-300 w-full sm:w-1/2" onClick={onClose} />
										<ButtonForm 
											bgColor="bg-teal-300" 
											textColor="" 
											text="Agregar al carrito" 
											width="w-full sm:w-1/2" 
											type="submit"
										/>
									</div>
								</section>
							)}
                        </form>
                    )}
                </Formik>
            </div>
        </main>
    );
}

export default ModalProduct;
