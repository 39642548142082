import React, { useEffect } from "react";
import H2Custom from "../atoms/H2Custom";
import { useNavigation } from "../Hooks/useNavigation";
import { useNavigation as useNavigation2 } from "../../componentsTheLaundry/Hooks/useNavigation";
import Logo from "../assets/Imagenes/logo.png"
import Logo2 from "../../componentsTheLaundry/assets/Imagenes/lau.png"

const NotFound = () => {
    const navigate = useNavigation();
    const navigate2 = useNavigation2();

    useEffect(() => {
		document.title = "No se encontró 🙀";
	}, []);

    return(
        <div className="h-screen w-screen flex flex-col justify-center items-center bg-white">
            <div className="flex flex-col items-center">
                <H2Custom text="404 🙀" textBold size="text-3xl" colorText="text-black"/>
                <H2Custom text="LA PAGINA QUE ESTAS BUSCANDO NO EXISTE 🤕" textBold size="text-5xl" colorText="text-black"/>
                <H2Custom text="Ir a:" textBold size="text-2xl" colorText="text-black"/>
            </div>
            <div className="w-full flex flex-row space-x-4 justify-around items-center">
                <button
                    className="w-1/3 h-fit hover:bg-yellow-300 rounded-lg p-4"
                    onClick={navigate.navigateToAbout}
                >
                    <img src={Logo} alt="LOGO" />
                </button>
                <button
                    className="w-1/3 h-fit hover:bg-teal-200 rounded-lg p-4"
                    onClick={navigate2.navigateToHome}
                >
                    <img src={Logo2} alt="LOGO" />
                </button>
            </div>
        </div>
    )
}

export default NotFound;