import React, { useState, useEffect, useCallback } from "react";
import useModal from "../../Hooks/useModal";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import RadioButtonsList from "../../atoms/RadioButtonsList";
import Modal from "../../molecules/Modal";
import AgregarDireccion from "../../molecules/Guia/AgregarDireccion";
import { GET_ADDRESS_BY_USER } from "../../../apiUser";
import { useSession } from "../../../context/SessionContext";
import { PulseLoader } from "react-spinners";

const Direcciones = () => {
    const { openModal: openAdd, isOpen: isOpenAdd, closeModal: closeAdd } = useModal();
    const [address, setAddress] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useSession();

    const fetchAddress = useCallback (async () => {
        try {
            setLoading(true)
            const AddressData = await GET_ADDRESS_BY_USER(user?.id);
            setAddress(AddressData);
        } catch (error) {
            console.error("Error al obtener las direcciones", error);
        } finally {
            setLoading(false);
        }
    }, [user?.id]);

    useEffect(() => {
        fetchAddress();
    }, [fetchAddress]);

    if (loading) {
        return <PulseLoader color="#0b8500" size={200}/>;
    }

    return (
        <div className="w-full h-fit sm:h-fit overflow-scroll bg-gray-700 rounded-md p-2 sm:p-8 mb-4">
            <LargeText text="Direcciones guardadas" textBold colorText="text-yellow-300" />
            <div className="space-y-4 overflow-scroll">
                <RadioButtonsList
                    options={address[0]?.direcciones}
                    refetch={fetchAddress}
                    addressData={address}
                />
                <div className="w-full flex flex-col sm:flex-row sm:space-x-4">
                    <ButtonForm text="Agregar dirección" onClick={openAdd} bgColor="bg-yellow-300" textColor="text-black" />
                </div>
            </div>
            {isOpenAdd &&
                <Modal onClose={closeAdd} title="Agregar dirección" height="h-auto">
                    <AgregarDireccion onClose={closeAdd} refetch={fetchAddress} addressData={address} />
                </Modal>
            }
        </div>
    );
}

export default Direcciones;
