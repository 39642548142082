import React, { useEffect } from "react";
import SideBar from "../adminMolecules/SideBar";
import { checkSession } from "../../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSession } from "../../context/SessionContext";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { setUser } = useSession();

  useEffect(() => {
    const verify = async () => {
        try {
          const user = await checkSession();
          if (user.role !== "Admin") {
            toast.error("Acceso denegado");
            navigate(`/login`);
          }
          setUser(user);
        } catch (error) {
          navigate(`/login`);
          toast.error("Sesión no válida");
        }
    };

    verify();
}, [navigate, setUser]);


    return (
      <div className="flex min-h-screen">
        <SideBar />
        <div className="flex-grow flex flex-col">
          <main className="h-full p-6 bg-white">
            {children}
          </main>
        </div>
      </div>
    );
  };

export default Layout;