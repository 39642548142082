import React from "react";
import LargeText from "./LargeText";

const NoData = () => {
    return (
        <div className="py-4">
            <LargeText text="No hay datos disponibles 😿" colorText="text-red-500" textBold size="text-2xl" />
        </div>
    )
}

export default NoData;