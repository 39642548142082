import React, { useEffect } from "react";
import { Formik } from "formik";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigation } from "../Hooks/useNavigation";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import RegisterField from "../atoms/RegisterField";
import { LOGIN_USER_EXT } from "../../apiTheLaundry/api";
import contenedorNeon from "../assets/Imagenes/Laublanco.png";
import contenedor from "../../components/assets/Imagenes/OIG4.jpeg";
import H2Custom from "../atoms/H2Custom";

const IngresarTL = () => {
	const navigate = useNavigation();

	useEffect(() => {
		document.title = "Inicio de sesión - The Laundry";
	}, []);

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			const dataUser =  await LOGIN_USER_EXT(values.email, values.password);
            if (dataUser) {
				navigate.navigateToHome();
			}
		} catch (error) {
			console.error(error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleCancel = () => {
		navigate.navigateToHome();
	};

	const validate = (values) => {
		const errors = {};
		if (!values.email) {
			errors.email = "Campo requerido";
		}
		if (!values.password) {
			errors.password = "Campo requerido";
		}
		return errors;
	};

	return (
		<div
			style={{
				backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, .8)), url(${contenedor})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
			}}
			className="w-screen h-screen bg-black flex justify-center items-center flex-col px-4"
		>
			<Formik
				initialValues={{ email: "" }}
				validate={validate}
				onSubmit={handleSubmit}
			>
				{({ handleSubmit, isSubmitting }) => (
					<form
						className="w-full sm:w-1/3 flex flex-col items-center justify-center text-center"
						onSubmit={handleSubmit}
					>
						<img src={contenedorNeon} alt="contenedor" />
						<H2Custom text="Inicia sesión" size="text-5xl" textBold={true} />
						<LargeText text="Ingresa tu nombre de correo electrónico" textBold={true} />
						<RegisterField
							name="email"
							type="text"
							placeholder="email@example.com"
						/>
						<RegisterField
							name="password"
							type="password"
							placeholder="Contraseña"
						/>
						<ButtonForm
							type="submit"
							disabled={isSubmitting}
							text={isSubmitting ? "Cargando..." : "Continuar"}
							bgColor={isSubmitting ? "bg-gray-400" : "bg-teal-300"}
							textColor="text-black"
						/>
					</form>
				)}
			</Formik>
                <ButtonForm
					type="submit"
					text="Registrate"
					bgColor="bg-teal-300 bg-opacity-75"
                    onClick={navigate.navigateToRegister}
                    textColor="text-black"
                    width="w-1/3"
				/>
                <FaArrowLeftLong
                    className="text-teal-300 w-24 h-6 mt-4 cursor-pointer"
                    onClick={handleCancel}
                />
		</div>
	);
};

export default IngresarTL;
