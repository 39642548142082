import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { FiAlertTriangle } from "react-icons/fi";
import { MdOutlineEdit, MdOutlineDeleteOutline, MdCompareArrows, MdAssignmentAdd } from "react-icons/md";
import useModal from "../../Hooks/useModal";
import SetInsumo from "./SetInsumo";
import AddInsumo from "./AddInsumo";
import EditInsumo from "./EditInsumo";
import MoveInsumo from "./MoveInsumo";
import ButtonForm from "../../atoms/ButtonForm";
import ModalAdmin from "../../adminMolecules/ModalAdmin";
import ModalConfirmAdmin from "../../adminMolecules/ModalConfirmAdmin";
import { DELETE_CONSUMABLE } from "../../../apiAdmin";
import NoData from "../../atoms/NoData";
import * as XLSX from "xlsx"; // Cambia la importación a un namespace
import { saveAs } from "file-saver"; // Mantén esta importación igual

const AlmacenTable = ({ data, cocinas, refetch, loading }) => {
    const { openModal, closeModal, isOpen } = useModal();
    const { openModal: openAdd, closeModal: closeAdd, isOpen: isOpenAdd } = useModal();
    const { openModal: openSet , closeModal: closeSet , isOpen: isOpenSet } = useModal();
    const { openModal: openMove , closeModal: closeMove , isOpen: isOpenMove } = useModal();
    const { openModal: openDelete , closeModal: closeDelete , isOpen: isOpenDelete } = useModal();
    const [insumo, setInsumo] = useState();

    const exportToExcel = () => {
        // Mapear la data para excluir columnas "Medida" y "Acciones"
        const filteredData = data.map(row => ({
            ID: row.id,
            Nombre: row.almacen.nombre,
            "En almacén": row.almacen.cantidad,
            Botellas: row.cantidad_disponible,
            "Total botellas": row.cantidad_disponible + row.almacen.cantidad,
            Bar: cocinaMap[row.almacen.id_cocina] || "Sin cocina"
        }));

        // Crear una hoja de trabajo
        const ws = XLSX.utils.json_to_sheet(filteredData);

        // Crear un libro de trabajo
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Almacén");

        // Generar archivo Excel
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        // Guardar archivo
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(dataBlob, "Almacen.xlsx");
    };

    const cocinaMap = {};
    cocinas?.forEach(cocina => {
        cocinaMap[cocina.id] = cocina.nombre;
    });

    const handleSet = (row) => {
        setInsumo(row);
        openSet();
    }

    const handleEdit = (row) => {
        setInsumo(row);
        openModal();
    }

    const handleAdd = (row) => {
        setInsumo(row);
        openAdd();
    }

    const handleMove = (row) => {
        openMove();
        setInsumo(row);
    }

    const handleClose = () => {
        closeModal();
        closeMove();
        closeAdd();
        closeSet();
        setInsumo(null);
    }

    const handleOpenDelete = (row) => {
        openDelete();
        setInsumo(row.id);
    }

    const handleDelete = async () => {
        try {
            await DELETE_CONSUMABLE(insumo);
            toast.success("Insumo eliminado correctamente");
            refetch();
            closeDelete();
        } catch (error) {
            console.error("Error al eliminar el insumo", error);
            toast.error("Error al eliminar el insumo")
        }
    }

    const columns = [
        { name:"ID", selector: row => row.id, sortable: true, width:"60px" },
        { name:"Nombre", selector: row => row.almacen.nombre, sortable: true, width:"250px" },
        { 
            name:"En almacen", 
            selector: row =>
                <div className="flex flex-row items-center">
                    {row.almacen.cantidad} 
                    <ButtonForm bgColor="bg-green-600" text={<MdAssignmentAdd size={30}/>} width="w-12 ml-2" onClick={()=> handleAdd(row)} /> 
                </div>, 
            sortable: true,  
            width:"115px" 
        },
        { name:
                <div className="flex flex-col">
                    <label>Botellas</label>
                    <label>Bateo</label>
                </div>, 
            selector: row => row.cantidad_disponible, sortable: true,  width:"120px" 
        },
        { name:"Total botellas", selector: row => row.cantidad_disponible + row.almacen.cantidad, sortable: true, width:"150px" },
        { name: 
                <div className="flex flex-col">
                    <label>Copas</label>
                    <label>Bateo</label>
                </div>, 
            selector: row => row.medidas_totales, sortable: true, width:"100px" 
        },
        { name:"Bar", selector: row => cocinaMap[row.almacen.id_cocina] || "Sin cocina", width:"130px" },
        { name:"Medida", selector: row => row.medidas, width:"75px"},
        {
            name:"Acciones",
            selector: row => 
                <div className="grid grid-cols-2 space-x-2">
                    <ButtonForm bgColor="bg-blue-500" text={<MdOutlineEdit size={30}/>} width="w-12" onClick={()=> handleEdit(row)} />
                    <ButtonForm bgColor="bg-orange-500" text={<MdCompareArrows size={30}/>} width="w-12" onClick={()=> handleMove(row)} />
                    <ButtonForm bgColor="bg-yellow-300" text={<FiAlertTriangle size={30} color="black"/>} width="w-12" onClick={()=> handleSet(row)} />
                    <ButtonForm bgColor="bg-red-500" text={<MdOutlineDeleteOutline size={30}/>} width="w-12" onClick={()=> handleOpenDelete(row)} />
                </div>
        },
    ]

    return(
        <div>
            <button onClick={exportToExcel} className="bg-blue-500 text-white py-2 px-4 rounded mb-4">
                Exportar a Excel
            </button>
            <DataTable
                data={data} columns={columns} pagination paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50, 100]} progressPending={loading}
                noDataComponent={<NoData/>}
            />
            {isOpenAdd &&
                <ModalAdmin title="Agregar insumo" onClose={handleClose}>
                    <AddInsumo row={insumo} cocinas={cocinas} refetch={refetch} onClose={handleClose}/>
                </ModalAdmin>
            }
            {isOpenSet &&
                <ModalAdmin title="Crear un ajuste" onClose={handleClose}>
                    <SetInsumo row={insumo} cocinas={cocinas} refetch={refetch} onClose={handleClose}/>
                </ModalAdmin>
            }
            {isOpen &&
                <ModalAdmin title="Editar" onClose={handleClose}>
                    <EditInsumo row={insumo} cocinas={cocinas} refetch={refetch} onClose={handleClose}/>
                </ModalAdmin>
            }
            {isOpenMove && 
                <ModalAdmin title="Mover" onClose={handleClose}>
                    <MoveInsumo row={insumo} cocinas={cocinas} refetch={refetch} onClose={handleClose}/>
                </ModalAdmin>
            }
            {isOpenDelete &&
                <ModalConfirmAdmin onClose={closeDelete} onConfirm={handleDelete} />
            }
        </div>
    )
}
export default AlmacenTable;