import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../Hooks/useNavigation";
import { MdLocationOn } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa6";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import LargeText from "../atoms/LargeText";
import contenedorNeon from "../assets/Imagenes/neon.webp";

const Footer = () => {
	const navigate = useNavigation();
	const location = useLocation();

	const handleArrowClick = () => {
		const sectionId = "top";
		navigate.navigateToCurrentSection(sectionId);
	};

	return (
		<div className="bg-[#006437] w-screen sm:h-52 flex flex-col sm:flex-row">
			{location.pathname === "/about"}
			<div className="hidden sm:flex flex-col h-full items-center justify-center w-[40%]">
				<div className="hidden sm:flex flex-col justify-center pl-2 h-full w-auto">
					<a
						href="https://www.google.com.mx/maps/place/Contenedor+Puebla/@19.0039722,-98.2722756,18z/data=!4m14!1m7!3m6!1s0x85cfc7002b792c67:0xa2d22f664f886edd!2sContenedor+Puebla!8m2!3d19.0039722!4d-98.2711944!16s%2Fg%2F11y92f8m9t!3m5!1s0x85cfc7002b792c67:0xa2d22f664f886edd!8m2!3d19.0039722!4d-98.2711944!16s%2Fg%2F11y92f8m9t?entry=ttu&g_ep=EgoyMDI1MDEwOC4wIKXMDSoASAFQAw%3D%3D"
						target="_blank"
						className="w-auto"
						rel="noopener noreferrer"
					>
						{[
							"Lateral Sur de la Vía Atlixcayotl, 6823",
							"San Antonio Cacalotepec, 72828",
							"Heroica Puebla de Zaragoza, Puebla, México",
						].map((text, index) => (
							<LargeText key={index} textBold={true} text={text} />
						))}
					</a>
				</div>
			</div>
			<div className="h-full w-full sm:w-[30%] text-center">
				<div className="h-[10%] flex  justify-center">
					<MdKeyboardDoubleArrowUp
						className="w-8 h-8 text-yellow-300 cursor-pointer"
						onClick={handleArrowClick}
					/>
				</div>
				{location.pathname === "/about" ? (
					<div className="h-[40%] w-full flex flex-col">
						<LargeText text="Inicio" textBold={true} size="text-2xl" />
						<div className="hidden flex-row justify-around w-full">
							<LargeText
								text="Registrarse"
								textBold
								cursor="cursor-pointer"
								onClick={navigate.navigateToRegister}
							/>
							<LargeText
								text="Ingresar"
								textBold
								cursor="cursor-pointer"
								onClick={navigate.navigateToLogin}
							/>
						</div>
					</div>
				) : (
					<div className="h-[40%] flex flex-col">
						<div className="flex flex-row justify-around w-full">
							<LargeText
								text="Inicio"
								cursor="cursor-pointer"
								onClick={navigate.navigateToHome}
								size="text-lg sm:text-2xl"
							/>
							<LargeText
								text="Cocinas"
								cursor="cursor-pointer"
								onClick={navigate.navigateToCocinasSection}
								size="text-lg sm:text-2xl"
							/>
							<LargeText
								text="Guía"
								cursor="cursor-pointer"
								onClick={navigate.navigateToGuia}
								size="text-lg sm:text-2xl"
							/>
							<LargeText
								text="Configuración"
								cursor="cursor-pointer"
								onClick={navigate.navigateToConfig}
								size="text-lg sm:text-2xl"
							/>
						</div>
					</div>
				)}
				<div className="w-full h-full flex flex-col items-center sm:hidden">
					<div className="w-full flex flex-row px-4 py-4 ">
						<div className="w-full flex flex-col items-center justify-center">
							<LargeText text="Redes Sociales" textBold={true} />
							<div className="w-full flex flex-row justify-around items-center text-white ">
								<a
									href="https://www.facebook.com/profile.php?id=61559936656892&mibextid=LQQJ4d"
									target="_blank"
									className="cursor-pointer"
									rel="noopener noreferrer"
								>
									<FaFacebook className="cursor-pointer" size={30} />
								</a>
								<a
									href="https://www.instagram.com/contenedor_pue?igsh=MXkyaHd1NTQ0MHFnZQ=="
									target="_blank"
									className="cursor-pointer"
									rel="noopener noreferrer"
								>
									<FaInstagram className="cursor-pointer" size={30} />
								</a>
								<a
									href="https://www.tiktok.com/@contenedor_pue?_t=8oIq3JaIQEg&_r=1"
									target="_blank"
									className="cursor-pointer"
									rel="noopener noreferrer"
								>
									<FaTiktok className="cursor-pointer" size={30} />
								</a>
								<a
									href="https://www.google.com/maps/place/Contenedor+Puebla/@19.0039722,-98.2711944,15z/data=!4m6!3m5!1s0x85cfc7002b792c67:0xa2d22f664f886edd!8m2!3d19.0039722!4d-98.2711944!16s%2Fg%2F11y92f8m9t?entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
									target="_blank"
									className="cursor-pointer text-white"
									rel="noopener noreferrer"
								>
									<MdLocationOn size={33} className="cursor-pointer" />
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="h-[30%] flex items-center">
					<img src={contenedorNeon} alt="LOGO" />
				</div>
				<div className="w-full flex flex-row justify-between p-4">
					<LargeText text="Derechos reservados @2024" size="text-xs" />
					<LargeText text="Dudas o aclaraciones" size="text-xs" />
				</div>
			</div>
			<div className="h-full w-[40%]">
				<div className="h-[70%] hidden sm:flex flex-col justify-center items-end space-y-4 pr-16">
					<LargeText text="Redes Sociales" textBold={true} />
					<div className="flex flex-row space-x-3">
						<a
							href="https://www.facebook.com/profile.php?id=61559936656892&mibextid=LQQJ4d"
							target="_blank"
							className="cursor-pointer"
							rel="noopener noreferrer"
						>
							<FaFacebookF
								className="bg-blue-700 text-white pt-1.5 rounded-md cursor-pointer"
								size={30}
							/>
						</a>
						<a
							href="https://www.instagram.com/contenedor_pue?igsh=MXkyaHd1NTQ0MHFnZQ=="
							target="_blank"
							className="cursor-pointer"
							rel="noopener noreferrer"
						>
							<FaInstagram
								className="p-1 rounded-md bg-gradient-to-tr from-pink-500 via-pink-500 to-yellow-300 text-white cursor-pointer"
								size={30}
							/>
						</a>
						<a
							href="https://www.tiktok.com/@contenedor_pue?_t=8oIq3JaIQEg&_r=1"
							target="_blank"
							className="cursor-pointer"
							rel="noopener noreferrer"
						>
							<FaTiktok
								className="text-white bg-black p-1 rounded-md cursor-pointer"
								size={30}
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
