import React, { useCallback, useEffect, useState } from "react";
import { CREATE_REGISTER_CUT, GET_CAJA_START, UPDATE_CAJA_START } from "../../apiCaja";
import { toast } from "react-toastify";
import { Formik } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";
import LargeText from "../atoms/LargeText";
import { useSession } from "../../context/SessionContext";
import { PulseLoader } from "react-spinners";

const RealizarCorte = ({ onClose, totalEfectivo, totalTarjeta, totalGeneral, refetchCortes, closeCortes, updateAllOrders }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const { user } = useSession();

    const calcularTotalCaja = (dinero_caja, totalEfectivo, cantidad_retiro) => {
        if (!cantidad_retiro) return dinero_caja + totalEfectivo;
        return dinero_caja + totalEfectivo - parseFloat(cantidad_retiro);
    };      

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setLoading(true);
            const cantidadRestante = (totalEfectivo + status?.dinero_caja) - values.cantidad_retiro;
            const updatedValues = {...values, cantidad_restante: cantidadRestante}
            await CREATE_REGISTER_CUT(updatedValues); 
            handleUpdateCashier(cantidadRestante);
            updateAllOrders();
            refetchCortes();
            onClose();
            closeCortes();
            toast.success("Corte de caja creado correctamente ✅✂️");
        } catch (error) {
            toast.error("Error al crear el corte de caja ❌✂️");
        } finally {
            setLoading(false);
        }
        setSubmitting(false);
    };

    const handleUpdateCashier = async (cantidadRestante) => {
        try {
            setLoading(true);
            const value  = {dinero_caja: cantidadRestante}
            await UPDATE_CAJA_START(status?.id, value); 
            toast.success("Caja actualizada correctamente ✅✂️");
        } catch (error) {
            toast.error("Error al actualizar la caja ❌✂️");
        } finally {
            setLoading(false);
        }
    };

    const fetchStartCaja = useCallback(async () => {
		try {
            setLoading(true);
			const statusData = await GET_CAJA_START();
			setStatus(statusData[0]);
		} catch (error) {
            onClose();
            closeCortes();
            toast.error("Error al intentar crear el corte");
			console.error(error);
		} finally {
            setLoading(false);
        }
	}, [closeCortes, onClose]);

    useEffect(() => {
		fetchStartCaja();
	}, [fetchStartCaja]);

    const initialValues = { 
        cantidad_retiro:"", 
        cantidad_tarjeta:totalTarjeta, 
        cantidad_efectivo:totalEfectivo, 
        quien_cerro:"",
        status_corte:"", 
        comentarios:"",
        id_caja:user?.id,
    }

    const validate = (values) => {
		const errors = {};
        if (!values.cantidad_retiro) { errors.cantidad_retiro = "Campo requerido"; }
        else if (values.cantidad_retiro < 0) {
            errors.cantidad_retiro = "No puede ser negativo";
        }
        else if (values.cantidad_retiro > totalEfectivo + status?.dinero_caja) {
            errors.cantidad_retiro = "Supera al efectivo en caja";
        }
		if (values.cantidad_tarjeta < 0) { errors.cantidad_tarjeta = "Campo requerido"; }
		if (values.cantidad_efectivo < 0) { errors.cantidad_efectivo = "Campo requerido"; }
        if (!values.quien_cerro) { errors.quien_cerro = "Campo requerido"; }
        if (!values.status_corte) { errors.status_corte = "Campo requerido"; }
		return errors;
	};

    const options = [
        {label: "Completo", value:true},
        {label: "Incompleto", value:false},
    ];

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} enableReinitialize={true} >
                {({values, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className="w-full flex flex-row space-x-4">
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="cantidad_retiro" label="Cantidad a retirar" type="number" placeholder="$"/>
                            <InputFFAdmin name="quien_cerro" label="Cerrado por" />  
                        </div>
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="status_corte" label="Estatus del corte" type="select" options={options} />
                            <InputFFAdmin name="comentarios" label="Comentarios" />
                        </div>
                    </div>
                    <div className="flex flex-col mt-6">
                        <div className="flex flex-row justify-between">
                            <LargeText text={`Tarjeta: $${totalTarjeta}`} colorText="text-red-600" size="text-xl" textBold/>
                            <LargeText text={`Efectivo: $${totalEfectivo}`} colorText="text-red-600" size="text-xl" textBold/>
                            <LargeText text={`Total entrante: $${totalGeneral}`} colorText="text-red-600" size="text-xl" textBold/>
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text={`Cantidad de inicio: $${status?.dinero_caja}`} colorText="text-red-600" size="text-xl" textBold/>
                            <LargeText 
                                text={`Total efectivo en caja: $${status?.dinero_caja + totalEfectivo}`} 
                                colorText="text-red-600" size="text-xl" textBold
                            />
                            <LargeText
                                text={`Restante en caja: $${calcularTotalCaja(status?.dinero_caja, totalEfectivo, values.cantidad_retiro)}`}
                                colorText="text-red-600" size="text-xl" textBold
                            />
                        </div>
                        </div>
                    <ButtonForm
                        text={loading ? <PulseLoader /> : "Guardar cambios"} type="submit"
                        bgColor={loading ? "bg-gray-300" : "bg-violet-300"} width="w-1/3 ml-auto mt-6" 
                        disabled={loading}
                    />
                </form>
                )}
            </Formik>
        </div>
    );
}
export default RealizarCorte;