import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: { "Content-Type": "application/json", Platform: "web" },
});

export const GET_ORDERS_CAJA = async () => {
	try {
		const response = await api.get("/api/ordersForCashier");
		return response.data;
	} catch (error) {
		console.error("Error fetching orders:", error);
		throw error;
	}
};

export const GET_ORDER_BY_ID = async (id) => {
	try {
		const response = await api.get(`/api/orderHistory/${id}`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener la orden:", error);
		throw error;
	}
};

export const GET_HISTORIAL = async () => {
	try {
		const response = await api.get("/api/ordersHistory");
		return response.data;
	} catch (error) {
		console.error("Error fetching historial:", error);
		throw error;
	}
};

export const GET_PENDIENTES = async () => {
	try {
		const response = await api.get("/api/orderHistoryCashier");
		return response.data;
	} catch (error) {
		console.error("Error fetching historial:", error);
		throw error;
	}
};

export const GET_FOR_CUT = async () => {
	try {
		const response = await api.get("/api/orderHistoryCut");
		return response.data;
	} catch (error) {
		console.error("Error fetching historial:", error);
		throw error;
	}
};

export const UPDATE_ORDER = async (id, updatedValues) => {
	try {
		const response = await api.put(`/api/orderHistory/${id}`, updatedValues);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar la orden:", error);
		throw error;
	}
}

export const GET_CAJA_ID = async (id) => {
	try {
		const response = await api.get(`/api/cashier/user/${id}`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener la caja", error);
		throw error;
	}
};

export const GET_CAJA_START = async () => {
	try {
		const response = await api.get("/api/cashRegisterStart");
		return response.data;
	} catch (error) {
		console.error("Error fetching el status de la caja:", error);
		throw error;
	}
};

export const UPDATE_CAJA_START = async (id, updatedValues) => {
	try {
		const response = await api.put(`/api/cashRegisterStart/${id}`, updatedValues);
		return response.data;
	} catch (error) {
		console.error("Error al actualizar la caja:", error);
		throw error;
	}
}

export const GET_HISTORIAL_CORTES = async () => {
	try {
		const response = await api.get("/api/cashRegisterHistory");
		return response.data;
	} catch (error) {
		console.error("Error fetching el status de la caja:", error);
		throw error;
	}
};

export const CREATE_REGISTER_CUT = async (data) => {
	try {
		const response = await api.post("/api/cashRegisterHistory", data);
		return response.data;
	} catch (error) {
		console.error("Error al crear el corte", error);
		throw error;
	}
};
