import React, { useState } from "react";
import { Formik } from "formik";
import ButtonForm from "../atoms/ButtonForm";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { UPDATE_ADMIN } from "../../apiAdmin";
import { toast } from "react-toastify";

const EditPerfilAdm = ({ admin, refetch, onClose }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        try{
            setLoading(true);
            await UPDATE_ADMIN(admin.id_user, values);
            toast.success("Administrador actualizado correctamente ✅🍽");
            refetch();
            onClose();
        } catch (error){
            console.error("Error al actualizar el administrador:", error);
            toast.error("Error al actulizar el administrador ❌🍽")
        } finally {
            setLoading(false);
        }
    }

    const initialValues = {
        nombre: admin.nombre || "",
        apellido: admin.apellido || "",
        username: admin.user.username || "",
        password: ""
    }

    const validate = (values) => {
		const errors = {};
		if (!values.nombre) { errors.nombre = "Campo requerido"; } 
		if (!values.apellido) { errors.apellido = "Campo requerido"; }
		return errors;
	};

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className="w-full flex flex-row space-x-4">
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="nombre" label="Nombre(s)" type="text" placeholder="Nombre(s)" />
                            <InputFFAdmin name="username" label="Nombre de usuario" type="text" placeholder="Nombre de usuario" />
                        </div>
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="apellido" label="Apellidos(s)" type="text" placeholder="Apellido(s)" />
                            <InputFFAdmin name="password" label="Contraseña" type="password" />
                        </div>
                    </div>
                    <ButtonForm 
                        text={loading ? "Cargando" : "Guardar cambios"} type="submit" disabled={loading}
                        bgColor={loading ? "bg-gray-300" : "bg-violet-300"} width="w-1/3 ml-auto" 
                    />
                </form>
                )}
            </Formik>
        </div>
    );
}

export default EditPerfilAdm;